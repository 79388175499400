import { ThemeUICSSObject } from 'theme-ui';

export const textBoxStyle: ThemeUICSSObject = {
  flexDirection: 'column',
  height: '106px',
  width: '100%',
  borderColor: 'backgroundMedium',
  borderRadius: 2,
  borderWidth: '1px',
  padding: '12px 16px',
};

export const charCounterStyle: ThemeUICSSObject = {
  position: 'absolute',
  right: 0,
  top: -6,
  fontFamily: 'label',
  fontSize: 0,
};

export const labelStyle: ThemeUICSSObject = {
  fontSize: 1,
  fontFamily: 'body',
  color: 'secondaryText',
};

export const readOnlyLabelStyle: ThemeUICSSObject = {
  backgroundColor: 'backgroundWeak',
  color: 'text',
  height: 'auto',
  wordBreak: 'break-word',
};
