import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  GET_ROUTING_IMPORT_PLAN,
  GET_ROUTING_IMPORT_PLAN_CUT_OFF,
} from 'src/graphql/requests/queries';
import { client } from 'src/graphql/client';
import {
  RouteImportPlan,
  RouteImportState,
  Tense,
} from 'src/graphql/types/delivery';
import {
  GGetRoutingImportPlanCutOffQuery,
  GGetRoutingImportPlanQuery,
  GRoutingImportPlanStatus,
} from 'src/graphql/types/generated';
import { DeliveryState } from './state';
import { getEWR1Id, getNYC1Id, isDefined } from 'src/utils';
import { getRubyFriendlyUtcStr, pastPresentFutureDate } from 'src/utils/date';
import { RootState } from 'src/state/store';
import { getRoutes } from './getRoutes';

const isTruckCountGreaterThanZero = (plans: RouteImportPlan[]): boolean => {
  let foundGreaterThanZeroCount = false;
  plans.forEach((plan) => {
    if ((plan.truckCount ?? 0) > 0) foundGreaterThanZeroCount = true;
  });
  return foundGreaterThanZeroCount;
};

const isRouteImportRunning = (
  data: GGetRoutingImportPlanCutOffQuery,
  plans: RouteImportPlan[],
  myTense?: Tense,
  isCurrentNightEnd?: boolean,
  // eslint-disable-next-line max-params
): boolean => {
  // check if we're on the current night end - this will cover most use cases
  // for warehouses that make deliveries on night they aren't scheduled to deliver - check that we are in the present
  // if we're in the past we can assume that routing has run so not loading
  // if we're in the future we can assume that routing has not run yet so not loading
  if (!isCurrentNightEnd && myTense !== Tense.present) return false;

  // if we don't have data yet we'll just assume the route import isn't running
  if (!data) return false;
  const isPastWarehouseCutOffTime = data.isPastWarehouseCutOffTime;
  const status = data.routingImportPlan.status;
  const truckCount = data.routingImportPlan.truckCount;

  // if there is no truck count the users can still edit the truck count
  if (!isDefined(truckCount)) return false;

  // if all the truck counts are zero, we're not routing anything so routing is not running
  if (!isTruckCountGreaterThanZero(plans)) return false;

  // if it's not past the cut off time, the route import is not running
  if (!isPastWarehouseCutOffTime) return false;

  // if it's past the cut off time and status is 'completed' it is not running
  if (
    isPastWarehouseCutOffTime &&
    status === GRoutingImportPlanStatus.Completed
  )
    return false;

  // if it's past the cut off time and status is anything but 'completed' we can assume it's running
  return true;
};

type GetRouteImportInfoThunk = {
  isRunning: boolean;
  plans: RouteImportPlan[];
  isPastWarehouseCutOffTime: boolean;
};

// Are we looking at the same plans from the previous state
const samePlans = (
  previousStateRouteImport: RouteImportState,
  currentStateRouteImport: GetRouteImportInfoThunk,
): boolean => {
  if (
    previousStateRouteImport.plans?.length !==
    currentStateRouteImport.plans?.length
  )
    return false;
  let arePlansSame = true;
  previousStateRouteImport.plans?.forEach((previousStatePlan): void => {
    if (
      currentStateRouteImport.plans?.find(
        (currentStatePlan) => currentStatePlan.id === previousStatePlan.id,
      ) === undefined
    ) {
      arePlansSame = false;
    }
  });
  return arePlansSame;
};

// have the values for isRunning or the truck count changed
const hasRouteImportFinished = (
  previousStateRouteImport: RouteImportState,
  currentStateRouteImport: GetRouteImportInfoThunk,
): boolean => {
  if (previousStateRouteImport.isRunning && !currentStateRouteImport.isRunning)
    return true;

  return false;
};

export const getRouteImportInfo = createAsyncThunk(
  'delivery/getRouteImportInfo',
  async (
    { viewingNightEnd }: { viewingNightEnd: string; isPolling?: boolean },
    thunkAPI,
  ): Promise<GetRouteImportInfoThunk | null> => {
    const { delivery }: RootState = thunkAPI.getState() as RootState;
    const myTense = pastPresentFutureDate(delivery, viewingNightEnd);
    const warehouseId = delivery.selectedWarehouseId;
    const previousStateRouteImport = { ...delivery.routeImport };
    const isEWR = warehouseId === getEWR1Id();
    const rubyFriendlyUtcStr = getRubyFriendlyUtcStr();
    if (!warehouseId || !viewingNightEnd) return null;
    const { data, error } = await client
      .query<GGetRoutingImportPlanCutOffQuery>(
        GET_ROUTING_IMPORT_PLAN_CUT_OFF,
        {
          routePlanInput: {
            warehouseId,
            nightEnd: viewingNightEnd,
          },
          cutOffInput: {
            dateTime: rubyFriendlyUtcStr,
            warehouseId,
          },
        },
        { requestPolicy: 'cache-and-network' },
      )
      .toPromise();

    let resultNyc1;
    if (isEWR) {
      resultNyc1 = await client
        .query<GGetRoutingImportPlanQuery>(GET_ROUTING_IMPORT_PLAN, {
          input: {
            warehouseId: getNYC1Id(),
            nightEnd: viewingNightEnd,
          },
        })
        .toPromise();
    }

    if (
      error ||
      (!data?.routingImportPlan && !data?.isPastWarehouseCutOffTime)
    ) {
      // TODO: do something with the error
      console.error('No routing import info found');
      console.error(error);
      return null;
    }

    const plans = [
      {
        ...data.routingImportPlan,
        label: isEWR ? 'EWR1' : undefined,
        warehouseId,
      },
    ];
    if (resultNyc1?.data?.routingImportPlan)
      plans.push({
        ...resultNyc1.data.routingImportPlan,
        label: 'NYC1',
        warehouseId: getNYC1Id(),
      });

    // IsPastWarehouseCutOffTime query does not work for future dates. Setting isPastWarehouseCutOffTime field to false if we are in the future.
    const currentStateRouteImport: GetRouteImportInfoThunk = {
      isRunning: isRouteImportRunning(
        data,
        plans,
        myTense,
        delivery.nightEnd.current === viewingNightEnd,
      ),
      plans,
      isPastWarehouseCutOffTime:
        myTense === Tense.future ? false : data.isPastWarehouseCutOffTime,
    };

    if (
      samePlans(previousStateRouteImport, currentStateRouteImport) &&
      hasRouteImportFinished(previousStateRouteImport, currentStateRouteImport)
    ) {
      thunkAPI.dispatch(getRoutes({ warehouseId, nightEnd: viewingNightEnd }));
    }
    return currentStateRouteImport;
  },
);

export const getRouteImportInfoFulfilled = (
  state: DeliveryState,
  action: PayloadAction<GetRouteImportInfoThunk | null>,
): void => {
  if (action.payload) {
    state.routeImport = {
      ...state.routeImport,
      ...action.payload,
      isLoading: false,
      isError: false,
    };
  } else {
    // if no data is returned, reset values
    state.routeImport = {
      ...state.routeImport,
      isRunning: false,
      plans: [],
      isPastWarehouseCutOffTime: false,
      isLoading: false,
      isError: true,
    };
  }
};

export const getRouteImportInfoPending = (
  state: DeliveryState,
  action: any,
): void => {
  const isPolling = action.meta.arg.isPolling;
  state.routeImport = {
    ...state.routeImport,
    isLoading: !isPolling,
    isError: false,
  };
};

export const getRouteImportInfoRejected = (state: DeliveryState): void => {
  state.routeImport = {
    ...state.routeImport,
    isLoading: false,
    isError: true,
  };
};
