import config from '../config';
import { sec } from '../services/authService';
import {
  createClient,
  dedupExchange,
  cacheExchange,
  fetchExchange,
  Exchange,
  Operation,
} from 'urql';
import { pipe, map, mergeMap, fromPromise, fromValue } from 'wonka';

const GATEWAY_API_URL = config().gateway_api_url;

export const PRODUCT = `routeManagement`;

const fetchOptionsExchange =
  (fn: any): Exchange =>
  ({ forward }) =>
  (ops$) => {
    return pipe(
      ops$,
      mergeMap((operation: Operation) => {
        const result = fn(operation.context.fetchOptions);
        return pipe(
          (typeof result.then === 'function'
            ? fromPromise(result)
            : fromValue(result)) as any,
          map((fetchOptions: RequestInit | (() => RequestInit)) => ({
            ...operation,
            context: { ...operation.context, fetchOptions },
          })),
        );
      }),
      forward,
    );
  };

export const client = createClient({
  url: process.env.MOCK === 'true' ? 'http://localhost:4000/' : GATEWAY_API_URL,
  exchanges: [
    dedupExchange,
    cacheExchange,
    fetchOptionsExchange(async (fetchOptions: any) => {
      const token =
        process.env.CYPRESS_AUTH_MOCKED === 'true'
          ? 'super_cool_token'
          : await sec.getAccessTokenSilently()({
              audience: GATEWAY_API_URL,
            });

      return Promise.resolve({
        ...fetchOptions,
        headers: {
          authorization: token ? `Bearer ${token}` : '',
          'apollographql-client-name': 'com.odeko.delivery-management.web',
          // TODO: add client version 'apollographql-client-version': '1.0.0'
        },
      });
    }),
    fetchExchange,
  ],
});
