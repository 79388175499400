import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { GET_PRIMARY_STOP_STATUSES } from 'src/graphql/requests/queries';
import { client } from 'src/graphql/client';
import { GetPrimaryStopStatuses } from 'src/graphql/types/delivery';
import {
  GDeliveryStatusDmp,
  GGetPrimaryStopStatusesQuery,
} from 'src/graphql/types/generated';
import {
  DeliveryState,
  RoutePrimaryStatusState,
  StopPrimaryStatusState,
} from './state';

export const getPrimaryStopStatuses = createAsyncThunk(
  'delivery/getPrimaryStopStatuses',
  async ({
    warehouseId,
    nightEnd,
  }: {
    warehouseId: string;
    nightEnd: string;
  }): Promise<GetPrimaryStopStatuses[]> => {
    const { data, error } = await client
      .query<GGetPrimaryStopStatusesQuery>(
        GET_PRIMARY_STOP_STATUSES,
        { input: { warehouseId, nightEnd } },
        { requestPolicy: 'cache-and-network' },
      )
      .toPromise();
    if (!data?.routes) {
      // TODO: do something with the error
      console.error('no GetAllStopStatusesRoutes returned', error);
      return [];
    }
    return data.routes;
  },
);

export const getPrimaryStopStatusesFulfilled = (
  state: DeliveryState,
  action: PayloadAction<GetPrimaryStopStatuses[]>,
): void => {
  const routes = [...action.payload];
  let routeStatuses: RoutePrimaryStatusState[] = [];
  let stopStatuses: StopPrimaryStatusState[] = [];
  let redeliveryStopStatuses: StopPrimaryStatusState[] = [];
  routes.forEach((route) => {
    const stops = route.stops ?? [];
    const myStopStatuses: StopPrimaryStatusState[] = stops.map((stop) => {
      return {
        routeId: route.id,
        stopId: stop.id,
        stopStatus: stop.deliveryStatusDmp,
      };
    });

    const redeliveryStops = route.redeliveryStops ?? [];
    const myRedeliveryStopStatuses: StopPrimaryStatusState[] =
      redeliveryStops.map((redeliveryStop) => {
        return {
          routeId: route.id,
          stopId: redeliveryStop.id,
          stopStatus: redeliveryStop.deliveryStatusDmp,
        };
      });

    // Delivery stops
    const inTransitStops =
      myStopStatuses.filter(
        (myStopStatus) =>
          myStopStatus.stopStatus === GDeliveryStatusDmp.InTransit,
      ) ?? [];
    const partiallyDeliveredStops =
      myStopStatuses.filter(
        (myStopStatus) =>
          myStopStatus.stopStatus === GDeliveryStatusDmp.PartiallyDelivered,
      ) ?? [];
    const undeliveredStops =
      myStopStatuses.filter(
        (myStopStatus) =>
          myStopStatus.stopStatus === GDeliveryStatusDmp.Undelivered,
      ) ?? [];

    // Pick up stops
    const notPickedUpStops =
      myStopStatuses.filter(
        (myStopStatus) =>
          myStopStatus.stopStatus === GDeliveryStatusDmp.NotPickedUp,
      ) ?? [];
    const partiallyPickedUpStops =
      myStopStatuses.filter(
        (myStopStatus) =>
          myStopStatus.stopStatus === GDeliveryStatusDmp.PartiallyPickedUp,
      ) ?? [];

    // Redelivery stops
    const inTransitRedeliveryStops =
      myRedeliveryStopStatuses.filter(
        (myStopStatus) =>
          myStopStatus.stopStatus === GDeliveryStatusDmp.InTransit,
      ) ?? [];
    const partiallyDeliveredRedeliveryStops =
      myRedeliveryStopStatuses.filter(
        (myStopStatus) =>
          myStopStatus.stopStatus === GDeliveryStatusDmp.PartiallyDelivered,
      ) ?? [];
    const undeliveredRedeliveryStops =
      myRedeliveryStopStatuses.filter(
        (myStopStatus) =>
          myStopStatus.stopStatus === GDeliveryStatusDmp.Undelivered,
      ) ?? [];

    const totalStops = myStopStatuses.length + myRedeliveryStopStatuses.length;
    const myRouteStatus = {
      routeId: route.id,
      totalStops,
      totalStopsVisited:
        totalStops - inTransitStops.length - inTransitRedeliveryStops.length,
      totalUndeliveredStops:
        undeliveredStops.length + undeliveredRedeliveryStops.length,
      totalPartiallyDeliveredStops:
        partiallyDeliveredStops.length +
        partiallyDeliveredRedeliveryStops.length,
      totalNotPickedUpStops: notPickedUpStops.length,
      totalPartiallyPickedUpStops: partiallyPickedUpStops.length,
    };

    routeStatuses.push(myRouteStatus);
    stopStatuses = stopStatuses.concat(myStopStatuses);
    redeliveryStopStatuses = redeliveryStopStatuses.concat(
      myRedeliveryStopStatuses,
    );
  });

  state.deliveryPrimaryStatuses = {
    routeStatuses,
    stopStatuses,
    redeliveryStopStatuses,
    loading: false,
  };
};

export const getPrimaryStopStatusesPending = (state: DeliveryState): void => {
  const hasCache = state.deliveryPrimaryStatuses.routeStatuses.length > 0;
  state.deliveryPrimaryStatuses.loading = hasCache ? false : true;
};

export const getPrimaryStopStatusesRejected = (state: DeliveryState): void => {
  state.deliveryPrimaryStatuses.loading = false;
  // TODO
};
