import { Flex, ThemeUICSSObject } from 'theme-ui';
import { Tooltip } from '@odekoteam/doppio';
import IconFrozen from 'assets/iconFrozen.svg';

const background: ThemeUICSSObject = {
  padding: '4px',
  borderRadius: '4px',
  alignItems: 'center',
  backgroundColor: 'backgroundMedium',
};

const countStyle: ThemeUICSSObject = {
  marginLeft: '5px',
  variant: 'text.labelSm',
  color: 'secondaryText',
};

const FreezerRequiredIcon = ({
  count,
  style = {},
  testId,
}: {
  count?: number;
  style?: ThemeUICSSObject;
  testId?: string;
}): JSX.Element | null => {
  if (count === 0) return null;

  return (
    <Tooltip labelText="Requires freezer" placement="bottom">
      <Flex
        data-test-id={`frozen-icon-${testId}`}
        sx={{ ...(count ? background : {}), ...style }}
      >
        <IconFrozen />
        {count && <span sx={countStyle}>{count}</span>}
      </Flex>
    </Tooltip>
  );
};

export default FreezerRequiredIcon;
