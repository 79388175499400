import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { CREATE_REDELIVERY_STOPS } from 'src/graphql/requests/mutations';
import { client } from 'src/graphql/client';
import {
  GCreateRedeliveryStopsMutation,
  GRedeliveryStopCreateInput,
} from 'src/graphql/types/generated';
import { NotificationType, showNotification } from '../notification';
import { IssuesState } from '../issues';
import { getRoutes } from '../delivery';

export const createRedeliveryStops = createAsyncThunk(
  'delivery/createRedeliveryStops',
  async (
    { warehouseId, nightEnd, stopIds }: GRedeliveryStopCreateInput,
    thunkAPI,
  ): Promise<
    GCreateRedeliveryStopsMutation['createRedeliveryStops'] | null
  > => {
    const { data, error } = await client
      .mutation<GCreateRedeliveryStopsMutation>(CREATE_REDELIVERY_STOPS, {
        input: { warehouseId, nightEnd, stopIds },
      })
      .toPromise();
    if (error || (data?.createRedeliveryStops?.userErrors.length ?? 0) > 0) {
      thunkAPI.dispatch(
        showNotification({
          type: NotificationType.Error,
          message:
            'The recovery stops can not be added to recovery route. Please try again.',
        }),
      );
      return null;
    }
    const redeliveryStops = data?.createRedeliveryStops?.redeliveryStops ?? [];
    const redeliveryRouteId = redeliveryStops[0].route?.id ?? undefined;
    const redeliveryRouteName = redeliveryStops[0].route?.name ?? undefined;
    const redeliveryRoute = redeliveryRouteId
      ? {
          id: redeliveryRouteId,
          name: redeliveryRouteName,
        }
      : undefined;
    thunkAPI.dispatch(
      showNotification({
        type: NotificationType.Success,
        message: 'The recovery stops have been added to ',
        route: redeliveryRoute,
      }),
    );
    thunkAPI.dispatch(
      getRoutes({ warehouseId, nightEnd: nightEnd ?? '', cached: true }),
    );
    if (!data?.createRedeliveryStops) return null;
    return data?.createRedeliveryStops;
  },
);

export const createRedeliveryStopsFulfillment = (
  state: IssuesState,
  action: PayloadAction<
    GCreateRedeliveryStopsMutation['createRedeliveryStops'] | null
  >,
): void => {
  const displayedStops = state.displayedStops ? [...state.displayedStops] : [];
  const redeliveryStops = action.payload?.redeliveryStops
    ? action.payload.redeliveryStops
    : [];

  redeliveryStops.forEach((redeliveryStop) => {
    const stopIndex = displayedStops.findIndex(
      (displayedStop) => displayedStop.id === redeliveryStop.deliveryStop.id,
    );
    if (displayedStops[stopIndex]) {
      displayedStops[stopIndex] = {
        ...displayedStops[stopIndex],
        redeliveryStopId: redeliveryStop.id,
        redeliveryStopStatus: redeliveryStop.deliveryStatusDmp,
        isChecked: false,
        loading: true,
      };
    }
  });

  state.displayedStops = displayedStops;
};
