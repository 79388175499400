import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { modalSelector } from 'src/state/selectors/modal';
import { hideModal } from 'src/state/slices/modal';
import { Button } from '@odekoteam/doppio';

const CancelButton = (): JSX.Element | null => {
  const { dismissButtonLabel } = useAppSelector(modalSelector);
  const dispatch = useAppDispatch();

  const handleOnClickCancel = (): void => {
    dispatch(hideModal());
  };

  return (
    <Button
      id="confirmation-modal-dismiss-button"
      variant="ghost"
      onPress={handleOnClickCancel}
    >
      {dismissButtonLabel}
    </Button>
  );
};
export default CancelButton;
