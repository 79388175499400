import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { GET_WAREHOUSES } from 'src/graphql/requests/queries';
import { client } from 'src/graphql/client';
import { Warehouse } from 'src/graphql/types/delivery';
import { GGetWarehousesQuery } from 'src/graphql/types/generated';
import { DeliveryState } from './state';
import { getSelectedWarehouse } from 'src/utils';
import { getModifiedIsoDate } from 'src/utils/date';

export const getWarehouses = createAsyncThunk(
  'delivery/warehouses',
  async (_: unknown): Promise<Warehouse[]> => {
    const { data, error } = await client
      .query<GGetWarehousesQuery>(GET_WAREHOUSES, {})
      .toPromise();
    if (error || !data?.warehouses) {
      // TODO: do something with the error
      console.error('No warehouses found');
      console.error(error);
      return [];
    }

    return data.warehouses;
  },
);

export const getWarehousesFulfilled = (
  state: DeliveryState,
  action: PayloadAction<Warehouse[]>,
): void => {
  state.warehouses = action.payload;
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const urlViewingNightEnd = urlParams.get('date');
  let selectedWarehouse = getSelectedWarehouse(
    state.selectedWarehouseId,
    state.warehouses,
  );
  if (selectedWarehouse === undefined && action.payload.length > 0) {
    selectedWarehouse = action.payload[0];
  }
  if (selectedWarehouse) {
    const roundedDate = getModifiedIsoDate(selectedWarehouse.currentNightEnd);
    state.selectedWarehouseId = selectedWarehouse.id;
    state.nightEnd.current = roundedDate;
    state.nightEnd.viewing = roundedDate;
  }
  if (urlViewingNightEnd) {
    state.nightEnd.viewing = urlViewingNightEnd;
  }
  state.warehousesLoading = false;
};

export const getWarehousesPending = (state: DeliveryState): void => {
  state.warehousesLoading = true;
};

export const getWarehousesRejected = (state: DeliveryState): void => {
  state.warehousesLoading = false;
  // TODO
};
