import { useQuery } from 'urql';
import { GGetWarehouseOrderCutOffTimeQuery } from 'src/graphql/types/generated';
import { GET_WAREHOUSE_ORDER_CUTOFF_TIME } from 'src/graphql/requests/queries';
import { isDefined } from 'src/utils';
import Skeleton from 'react-loading-skeleton';
import CutoffTagOrder from './CutoffTagOrder';

export enum CustomTagType {
  truncated = 'truncated',
  full = 'full',
}

const CutoffTagStop = ({
  stopId,
  tagType = CustomTagType.truncated,
}: {
  stopId?: string;
  tagType?: CustomTagType;
}): JSX.Element | null => {
  const [{ fetching, data }] = useQuery<GGetWarehouseOrderCutOffTimeQuery>({
    query: GET_WAREHOUSE_ORDER_CUTOFF_TIME,
    variables: { input: { id: stopId } },
    requestPolicy: 'cache-and-network',
    pause: !isDefined(stopId),
  });

  if (fetching) return <Skeleton sx={{ width: '52px', height: '28px' }} />;

  let cutoffTime = '';
  const hasWarehouseOrder =
    data?.stop.__typename === 'Delivery'
      ? data?.stop.orders?.some((order) => {
          cutoffTime = order.dueAt;
          return order.vendor.isWarehouse === true;
        })
      : false;

  return (
    <CutoffTagOrder
      tagType={tagType}
      dueAt={cutoffTime}
      isWarehouse={hasWarehouseOrder ?? false}
    />
  );
};

export default CutoffTagStop;
