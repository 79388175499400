import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { LocationState } from '../slices/locations';

export const locationsSelector: (state: RootState) => LocationState = (
  state: RootState,
) => state.delivery;

export const routingLocationsSelector = createSelector(
  locationsSelector,
  (locations) => {
    return locations.routingLocations;
  },
);
