import {
  Delivery,
  GetFulfillmentDelivery,
  PickUp,
  Route,
} from 'src/graphql/types/delivery';
import { TableCell, TableRow } from '../Table';
import { getPrintableStyle, StopNote } from './utils';
import { useAppDispatch } from 'src/hooks/store';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { setStopDetailsCardVisible } from 'src/state/slices/delivery';
import { deliveryPath } from '../Navbar';

const NotesRows = ({
  route,
  note,
  printable,
  label,
}: {
  route: Route;
  note: StopNote;
  printable?: boolean;
  label: string;
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const printableStyle = getPrintableStyle(printable);
  const id = label.replaceAll(' ', '-');

  return (
    <TableRow key={note.stop.id} data-test-id={note.stop.id}>
      <TableCell
        data-test-id={`${id}-route-stop-cell-${note.stop.id}`}
        style={{ ...printableStyle, cursor: 'pointer' }}
        onClick={(): void => {
          const myStop: Delivery | PickUp | undefined = route.stops?.find(
            (myStop) => myStop.id === note.stop.id,
          );
          if (myStop)
            dispatch(
              setStopDetailsCardVisible({
                stop: myStop,
                routeId: route.id,
              }),
            );
          navigate(`${deliveryPath}?${searchParams.toString()}`);
        }}
      >
        {route.name} Stop {Number(note.stop.sequenceNumber ?? 0) + 1}
      </TableCell>
      <TableCell>{label}</TableCell>
      <TableCell data-test-id={`${id}-cell-${note.stop.id}`}>
        {note.note}
      </TableCell>
    </TableRow>
  );
};

export default NotesRows;
