import { ReactNode } from 'react';
import { useLocation, Link, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'src/hooks/store';
import { collapseNav } from 'src/state/slices/app';
import { navLinkContainer, navLinkLabel } from './styles';

type NavLinkProps = {
  path: string;
  label: string;
  selectedIcon: ReactNode;
  unselectedIcon: ReactNode;
};

const NavLink = ({
  path,
  label,
  selectedIcon,
  unselectedIcon,
}: NavLinkProps): JSX.Element => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const id = path.replace(/\//g, '');
  const isSelected = path === location.pathname;
  const labelSelected = isSelected ? { fontFamily: 'heading' } : {};
  const [searchParams] = useSearchParams();

  const handleClick = (): void => {
    dispatch(collapseNav());
  };

  return (
    <Link
      id={`navLink${id}`}
      sx={navLinkContainer}
      to={{ pathname: path, search: `?${searchParams.toString()}` }}
      onClick={handleClick}
    >
      <div>{isSelected ? selectedIcon : unselectedIcon}</div>
      <div sx={{ ...navLinkLabel, ...labelSelected }}>{label}</div>
    </Link>
  );
};

export default NavLink;
