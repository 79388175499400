import {
  container,
  datePickerIconStyle,
  deliveryDateLabel,
  datePickerWrapper,
} from './styles';
import Calendar from './Calendar';
import { useRef, useState } from 'react';
import DateDisplay from './DateDisplay';
import { Flex, ThemeUICSSObject } from 'theme-ui';
import { useOutsideAlerter } from 'src/hooks/refs';
import { IconCalendar3 } from '@odekoteam/doppio';
import { useBreakpointIndex } from '@theme-ui/match-media';

const DatePickerDropdown = ({
  style,
  calendarStyles,
}: {
  style?: ThemeUICSSObject;
  calendarStyles?: ThemeUICSSObject[];
}): JSX.Element => {
  const breakpointIndex = useBreakpointIndex();
  const isMobileViewport = breakpointIndex <= 1;
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => setIsCalendarVisible(false));
  return (
    <Flex
      className="date-picker"
      sx={{ ...container, ...style }}
      ref={wrapperRef}
    >
      <div
        className="date-picker-wrapper"
        onClick={(): void => setIsCalendarVisible(!isCalendarVisible)}
        sx={datePickerWrapper}
      >
        <span sx={deliveryDateLabel}>Delivery Date</span>
        <DateDisplay />
        <span sx={datePickerIconStyle}>
          <IconCalendar3 aria-label="Date picker" />
        </span>
      </div>
      {isCalendarVisible && (
        <Calendar
          setIsCalendarVisible={setIsCalendarVisible}
          isMobileViewport={isMobileViewport}
          styles={calendarStyles}
        />
      )}
    </Flex>
  );
};
export default DatePickerDropdown;
