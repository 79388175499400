import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { GET_ROUTES } from 'src/graphql/requests/getRoutes';
import { client } from 'src/graphql/client';
import { Route } from 'src/graphql/types/delivery';
import { naturalSort, updateSequenceNumbersRedelivery } from 'src/utils';
import { GGetRoutesQuery } from 'src/graphql/types/generated';
import { DeliveryState } from './state';

type getRoutesArgs = {
  warehouseId: string;
  nightEnd: string;
  cached?: true;
};
export const getRoutes = createAsyncThunk(
  'delivery/routes',
  async ({ warehouseId, nightEnd }: getRoutesArgs): Promise<Route[]> => {
    const { data, error } = await client
      .query<GGetRoutesQuery>(
        GET_ROUTES,
        { input: { warehouseId, nightEnd } },
        { requestPolicy: 'cache-and-network' },
      )
      .toPromise();
    if (error || !data?.routes) {
      // TODO: do something with the error
      console.error('no routes returned', error);
      return [];
    }
    return data.routes as Route[];
  },
);

export const getRoutesFulfilled = (
  state: DeliveryState,
  action: PayloadAction<Route[]>,
): void => {
  const routes = [...action.payload];

  // update redelivery stop sequence numbers
  const updatedRoutes = routes.map(updateSequenceNumbersRedelivery);
  const lobbyRouteIndex = updatedRoutes.findIndex((route) => route.isLobby);
  const lobbyRoute = updatedRoutes[lobbyRouteIndex];
  lobbyRoute && updatedRoutes.splice(lobbyRouteIndex, 1);

  const myRoutes = (updatedRoutes as Route[]).sort(
    (aImpl: Route, bImpl: Route) => naturalSort(aImpl.name, bImpl.name),
  );

  state.routes = lobbyRoute ? [lobbyRoute, ...myRoutes] : myRoutes;
  state.routesLoading = false;
};

export const getRoutesPending = (
  state: DeliveryState,
  { meta: { arg } }: { meta: { arg: getRoutesArgs } },
): void => {
  // Disable routes sync button if we request routes
  state.isRoutesSyncEnabled = false;
  state.routesLoading = arg.cached ? false : true;
};

export const getRoutesRejected = (state: DeliveryState): void => {
  state.routesLoading = false;
  // TODO
};
