import { useEffect, useMemo, useState } from 'react';
import { useAppSelector, useAppDispatch } from '../hooks/store';
import {
  getWarehouses,
  setSelectedWarehouseId,
} from '../state/slices/delivery';
import {
  selectedWarehouseIdSelector,
  warehousesLoadingSelector,
  warehousesSelector,
} from '../state/selectors/delivery';
import { Warehouse } from 'src/graphql/types/delivery';
import Dropdown, { DropdownType, Option } from './Dropdown';
import { SingleValue } from 'react-select';
import { getSelectedWarehouse } from 'src/utils';

const WarehouseDropdown = (): JSX.Element | null => {
  const warehouses = useAppSelector(warehousesSelector);
  const warehousesLoading = useAppSelector(warehousesLoadingSelector);
  const selectedWarehouseId = useAppSelector(selectedWarehouseIdSelector);
  const dispatch = useAppDispatch();
  const warehouseOptions = useMemo(() => {
    const sortedWarehouses = warehouses ? [...warehouses] : [];
    sortedWarehouses.sort((a, b): number => {
      const nameA = a.name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    return (
      sortedWarehouses?.map((warehouse: Warehouse) => ({
        value: warehouse.id,
        label: warehouse.name,
      })) ?? []
    );
  }, [warehouses]);

  const handleChange = (newValue: SingleValue<Option>): void => {
    const selectedWarehouse = getSelectedWarehouse(newValue?.value, warehouses);
    if (selectedWarehouse)
      dispatch(
        setSelectedWarehouseId({
          warehouseId: selectedWarehouse.id,
        }),
      );
  };

  useEffect(() => {
    if (!warehouses) {
      dispatch(getWarehouses({}));
    } else {
      const foundWarehouse = getSelectedWarehouse(
        selectedWarehouseId,
        warehouses,
      );
      if (!foundWarehouse && warehouses.length > 0)
        dispatch(
          setSelectedWarehouseId({
            warehouseId: warehouses[0].id,
          }),
        );
    }
  }, [warehouses, dispatch, selectedWarehouseId]);

  if (warehousesLoading) return <div sx={{ height: '93px' }} />;

  return (
    <div
      sx={{
        alignSelf: 'flex-start',
        marginBottom: '4px',
        marginTop: '14px',
      }}
    >
      <Dropdown
        id="warehouses"
        options={warehouseOptions}
        onChange={handleChange}
        value={selectedWarehouseId}
        type={DropdownType.heading}
      />
    </div>
  );
};

export default WarehouseDropdown;
