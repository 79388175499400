import { ReactNode } from 'react';
import NavHamburger from './Navbar/NavHamburger';
import { useBreakpointIndex } from '@theme-ui/match-media';
import { ThemeUICSSObject } from 'theme-ui';

const LoggedInContainer = ({
  children,
  style,
}: {
  children: ReactNode;
  style?: ThemeUICSSObject;
}): JSX.Element => {
  const breakpointIndex = useBreakpointIndex();
  return (
    <div
      sx={{
        display: 'flex',
        height: '100%',
        flexDirection: breakpointIndex === 0 ? 'column' : 'row',
        ...style,
      }}
    >
      <NavHamburger />
      {children}
    </div>
  );
};

export default LoggedInContainer;
