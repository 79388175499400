import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { DELETE_REDELIVERY_STOP } from 'src/graphql/requests/mutations';
import { client } from 'src/graphql/client';
import {
  GRedeliveryStopDeleteInput,
  Scalars,
} from 'src/graphql/types/generated';
import { NotificationType, showNotification } from '../notification';
import { IssuesState } from '../issues';
import { getRoutes } from '../delivery';
import { RootState } from 'src/state/store';

export const deleteRedeliveryStop = createAsyncThunk(
  'delivery/deleteRedeliveryStop',
  async (
    { id }: GRedeliveryStopDeleteInput,
    thunkAPI,
  ): Promise<Scalars['ID'] | null> => {
    const rootState: RootState = thunkAPI.getState() as RootState;
    const nightEnd = rootState.delivery.nightEnd;
    const warehouseId = rootState.delivery.selectedWarehouseId;
    const { data, error } = await client
      .mutation(DELETE_REDELIVERY_STOP, {
        input: { id },
      })
      .toPromise();
    if (error || data.deleteRedeliveryStop.userErrors.length > 0) {
      thunkAPI.dispatch(
        showNotification({
          type: NotificationType.Error,
        }),
      );
      return null;
    }
    thunkAPI.dispatch(
      getRoutes({
        warehouseId: warehouseId ?? '',
        nightEnd: nightEnd.viewing ?? '',
        cached: true,
      }),
    );
    thunkAPI.dispatch(
      showNotification({
        type: NotificationType.Success,
        message: 'Redelivery stop has been deleted successfully',
      }),
    );
    return id;
  },
);

export const deleteRedeliveryStopFulfillment = (
  state: IssuesState,
  action: PayloadAction<Scalars['ID'] | null>,
): void => {
  const displayedStops = state.displayedStops ? [...state.displayedStops] : [];
  const stopIndex = displayedStops.findIndex(
    (stopDisplayed) => stopDisplayed.redeliveryStopId === action.payload,
  );
  if (displayedStops[stopIndex]) {
    displayedStops[stopIndex] = {
      ...displayedStops[stopIndex],
      redeliveryStopId: undefined,
      redeliveryStopStatus: undefined,
      loading: false,
    };
  }

  state.displayedStops = displayedStops;
};

export const deleteRedeliveryStopPending = (
  state: IssuesState,
  { meta: { arg } }: { meta: { arg: GRedeliveryStopDeleteInput } },
): void => {
  const displayedStops = state.displayedStops ? [...state.displayedStops] : [];
  const stopIndex = displayedStops.findIndex(
    (stopDisplayed) => stopDisplayed.redeliveryStopId === arg.id,
  );
  if (displayedStops[stopIndex]) {
    displayedStops[stopIndex] = {
      ...displayedStops[stopIndex],
      loading: true,
    };
  }

  state.displayedStops = displayedStops;
};
