import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { EDIT_ROUTE_DETAILS } from 'src/graphql/requests/mutations';
import { client } from 'src/graphql/client';
import { NotificationType, showNotification } from '../notification';
import { DeliveryState } from './state';
import {
  CANNOT_CHANGE_DATA,
  INVALID_NIGHT_END,
  isUserErrorInArray,
} from 'src/utils';
import {
  GRouteDetailsUpdateInput,
  GRouteDetailsUpdatePayload,
} from 'src/graphql/types/generated';

export const setRouteDetails = createAsyncThunk<
  GRouteDetailsUpdatePayload | null,
  GRouteDetailsUpdateInput
>(
  'delivery/updateRouteDetails',
  async (
    input: GRouteDetailsUpdateInput,
    thunkAPI,
  ): Promise<GRouteDetailsUpdatePayload | null> => {
    const { data, error } = await client
      .mutation(EDIT_ROUTE_DETAILS, { input })
      .toPromise();

    if (
      error ||
      !data.updateRouteDetails ||
      data.updateRouteDetails.userErrors.length > 0
    ) {
      const isInvalidNightEnd = isUserErrorInArray(
        INVALID_NIGHT_END,
        data.updateRouteDetails.userErrors,
      );
      const message = isInvalidNightEnd ? CANNOT_CHANGE_DATA : undefined;
      thunkAPI.dispatch(
        showNotification({
          type: NotificationType.Error,
          message,
        }),
      );
      return null;
    }

    thunkAPI.dispatch(
      showNotification({
        type: NotificationType.Success,
        message: 'Route details updated successfully',
      }),
    );

    return data.updateRouteDetails;
  },
);

export const setRouteDetailsFulfilled = (
  state: DeliveryState,
  action: PayloadAction<GRouteDetailsUpdatePayload | null>,
): void => {
  state.routeDetailsEditor.isSaving = false;

  if (action.payload?.route) {
    const currentRoutesState = state.routes ? [...state.routes] : [];
    const updatedRoute = action.payload?.route;

    state.routes = currentRoutesState.map((route) => {
      // update the route in the routes array via replacement
      if (route.id === updatedRoute.id) {
        return {
          ...route,
          driverUser: {
            id: updatedRoute.driverUser?.id || '',
            name: updatedRoute.driverUser?.name || '',
          },
        };
      }
      return route;
    });

    state.secondaryCardVisible = undefined;
  }
};

export const setRouteDetailsPending = (state: DeliveryState): void => {
  state.routeDetailsEditor.isSaving = true;
};

export const setRouteDetailsRejected = (state: DeliveryState): void => {
  state.routeDetailsEditor.isSaving = false;
};
