import { gql } from 'urql';
import { PRODUCT } from '../client';
import { ORDER_LINE } from './fragments';

export const GET_ITEMS = gql`
  ${ORDER_LINE}
  query ${PRODUCT}GetItemsPerRoute($input: RouteInput!) {
    route(input: $input) {
      id
      name
      isLobby
      stops {
        ... on Delivery {
          id
          sequenceNumber
          deliveryStatusDmp
          location {
            id
            businessName
          }
          orders {
            id
            erpRecordUrl
            itemLevelTracking
            fulfillmentStatus
            orderLines {
              ...OrderLineFragment
            }
            vendor {
              id
              name
            }
          }
        }
      }
      redeliveryStops {
        id
        sequenceNumber
        deliveryStatusDmp
        location {
          id
          businessName
        }
        orders {
          id
          erpRecordUrl
          itemLevelTracking
          fulfillmentStatus
          orderLines {
            ...OrderLineFragment
          }
          vendor {
            id
            name
          }
        }
      }
    }
  }
`;
