import { createSelector } from 'reselect';
import { RootState } from '../store';
import { ModalState } from '../slices/modal';

export const modalSelector: (state: RootState) => ModalState = (
  state: RootState,
) => state.modal;

export const isModalVisibleSelector = createSelector(modalSelector, (modal) => {
  return modal.isVisible;
});
