import { gql } from 'urql';
import { PRODUCT } from '../client';

export const MARK_ITEMS_AS_BULK_PICKED = gql`
  mutation ${PRODUCT}MarkItemsAsBulkPicked($input: MarkItemsAsBulkPickedMutationInput!) {
    markItemsAsBulkPicked(input: $input) {
      success
      userErrors {
        message
        path
      }
    }
  }
`;
