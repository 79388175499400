import config from '../../config';
import mixpanel, { Dict } from 'mixpanel-browser';

const debug = ['development', 'stage-1', 'stage-2'].includes(
  config().environment,
);
const is_automated_test = process.env.CYPRESS_AUTH_MOCKED === 'true';
mixpanel.init(config().mixpanel_token, { debug });

// @see https://medium.com/@andrewoons/setting-up-mixpanel-in-react-3e4c5b8c2a36
const prefix = 'DMP';

let analytics = {
  identify: (id: string): void => {
    if (is_automated_test || !id) return;
    mixpanel.identify(id);
  },
  track: (eventName: string, props = {}): void => {
    if (is_automated_test || !eventName || !props) return;
    mixpanel.track(`${prefix} - ${eventName}`, props);
  },
};

export default analytics;
