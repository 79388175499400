export type Config = {
  environment: string;
  gateway_api_url: string;
  so_api_url: string;
  google_maps_api_key: string;
  auth0_domain: string;
  auth0_client_id: string;
  auth0_roles_key: string;
  launchdarkly_client_side_id: string;
  looker_base_url: string;
  looker_embed_url: string;
  netsuite_url: string;
  netsuite_dashboard_id: string;
  mixpanel_token: string;
  portal_url: string;
};

const config = (): Config => ({
  environment: window.env.SERVICE_ENV,
  gateway_api_url: window.env.GRAPHQL_ENDPOINT,
  so_api_url: window.env.SO_API_URL,
  google_maps_api_key: window.env.GOOGLE_MAPS_API_KEY,
  auth0_domain: window.env.AUTH0_DOMAIN,
  auth0_client_id: window.env.AUTH0_CLIENT_ID,
  auth0_roles_key: window.env.AUTH0_ROLES_KEY,
  launchdarkly_client_side_id: window.env.LAUNCH_DARKLY_SDK_KEY,
  looker_base_url: window.env.LOOKER_BASE_URL,
  looker_embed_url: window.env.LOOKER_EMBED_URL,
  netsuite_url: window.env.NETSUITE_URL,
  netsuite_dashboard_id: window.env.NETSUITE_DASHBOARD_ID,
  mixpanel_token: window.env.MIXPANEL_TOKEN,
  portal_url: window.env.PORTAL_URL,
});

export default config;
