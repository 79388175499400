import { useAuth0 } from '@auth0/auth0-react';
import { navLinkContainer, navLinkLabel } from './styles';
import { IconSignOut2 } from '@odekoteam/doppio';

const LogoutButton = (): JSX.Element => {
  const { logout } = useAuth0();

  const handleClick = (): void => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };
  return (
    <div id="logoutButton" sx={navLinkContainer} onClick={handleClick}>
      <div>
        <IconSignOut2 width={24} height={24} />
      </div>
      <div sx={navLinkLabel}>Log out</div>
    </div>
  );
};

export default LogoutButton;
