import { Table, TableBody, TableHeader } from 'src/components/Table';
import FulfillmentByRoute from './FulfillmentByRoute';
import { Route } from 'src/graphql/types/delivery';

const labels: string[] = [
  'Route and Stop',
  'Location',
  'Vendor',
  'Order Links',
  'Order Lines With Fulfillment Issues',
];

const excludedLabels = ['Order Links'];
const FulfillmentTable = ({
  routes,
  printable = false,
}: {
  routes: Route[];
  printable?: boolean;
}): JSX.Element => {
  const filteredLabels = printable
    ? labels.filter((label) => !excludedLabels.includes(label))
    : labels;
  return (
    <Table id="fulfillmentTable">
      <TableHeader labels={filteredLabels} />
      <TableBody>
        {routes?.map((route) => (
          <FulfillmentByRoute
            key={route.id}
            route={route}
            printable={printable}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default FulfillmentTable;
