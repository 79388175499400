import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { modalSelector } from 'src/state/selectors/modal';
import { hideModal } from 'src/state/slices/modal';
import {
  Button,
  ButtonIcon,
  IconClose,
  IconLineArrowLeft,
  IconLineArrowRight,
  Spacer,
} from '@odekoteam/doppio';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import ClockwiseArrow from 'assets/clockwiseArrow.svg';
import CounterClockwiseArrow from 'assets/counterClockwiseArrow.svg';
import { styledFullscreenModal, exitButtonWrapper, image } from './styles';
import { useEffect, useRef, useState } from 'react';
import { Flex } from 'theme-ui';

const FullscreenModal = (): JSX.Element | null => {
  const {
    variables: { index, attachments },
  } = useAppSelector(modalSelector);
  const dispatch = useAppDispatch();
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [viewingIndex, setViewingIndex] = useState(index);
  const ref = useRef<any>(null);
  const [rotationIndex, setRotationIndex] = useState(0);
  const rotationDegrees = [0, 90, 180, 270];
  const { url } = attachments[viewingIndex];
  const isNextDisabled = viewingIndex === attachments.length - 1;
  const isPreviousDisabled = viewingIndex === 0;
  const arrowStyles = { alignSelf: 'center', marginX: '10px' };

  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.clientHeight - 80);
      setWidth(ref.current.clientWidth - 150);
    }
  }, []);

  const handleOnClickCancel = (): void => {
    dispatch(hideModal());
  };

  const handleOnNext = (): void => {
    setViewingIndex(viewingIndex + 1);
    setRotationIndex(0);
  };

  const handleOnPrevious = (): void => {
    setViewingIndex(viewingIndex - 1);
    setRotationIndex(0);
  };

  const handleRotateClockwise = (): void => {
    if (rotationIndex === 3) setRotationIndex(0);
    else setRotationIndex(rotationIndex + 1);
  };

  const handleRotateCounterClockwise = (): void => {
    if (rotationIndex === 0) setRotationIndex(3);
    else setRotationIndex(rotationIndex - 1);
  };

  return (
    <div id="fullscreen-modal" sx={styledFullscreenModal} ref={ref}>
      <div
        sx={exitButtonWrapper}
        onClick={handleOnClickCancel}
        id="fullscreen-modal-dismiss-button"
      >
        <IconClose width={24} height={24} />
      </div>
      <div sx={{ padding: '20px' }}>
        <TransformWrapper>
          {({ zoomIn, zoomOut, resetTransform }): JSX.Element => (
            <Flex>
              <span
                sx={{
                  ...arrowStyles,
                  visibility: isPreviousDisabled ? 'hidden' : 'inherit',
                }}
              >
                <ButtonIcon
                  variant="ghost"
                  onPress={(): void => {
                    handleOnPrevious();
                    resetTransform();
                  }}
                >
                  <IconLineArrowLeft />
                </ButtonIcon>
              </span>
              <div>
                <Flex className="tools" sx={{ marginY: '10px' }}>
                  <ButtonIcon
                    variant="tertiary"
                    onPress={handleRotateCounterClockwise}
                  >
                    <Flex>
                      <CounterClockwiseArrow />
                    </Flex>
                  </ButtonIcon>
                  <Spacer width="$1" />
                  <ButtonIcon
                    variant="tertiary"
                    onPress={handleRotateClockwise}
                  >
                    <Flex>
                      <ClockwiseArrow />
                    </Flex>
                  </ButtonIcon>
                  <Spacer width="$1" />
                  <Button variant="tertiary" onPress={(): void => zoomIn()}>
                    Zoom in +
                  </Button>
                  <Spacer width="$1" />
                  <Button variant="tertiary" onPress={(): void => zoomOut()}>
                    Zoom out -
                  </Button>
                  <Spacer width="$1" />
                  <Button
                    variant="secondary"
                    onPress={(): void => resetTransform()}
                  >
                    Reset zoom
                  </Button>
                </Flex>
                <TransformComponent contentStyle={{ height, width }}>
                  <img
                    src={url}
                    alt="new"
                    sx={{
                      ...image,
                      transform: `rotate(${rotationDegrees[rotationIndex]}deg)`,
                    }}
                  />
                </TransformComponent>
              </div>
              <span
                sx={{
                  ...arrowStyles,
                  visibility: isNextDisabled ? 'hidden' : 'inherit',
                }}
              >
                <ButtonIcon
                  variant="ghost"
                  onPress={(): void => {
                    handleOnNext();
                    resetTransform();
                  }}
                >
                  <IconLineArrowRight />
                </ButtonIcon>
              </span>
            </Flex>
          )}
        </TransformWrapper>
      </div>
    </div>
  );
};
export default FullscreenModal;
