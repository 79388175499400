import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { GET_ALL_STOP_STATUSES } from 'src/graphql/requests/getAllStopStatuses';
import { client } from 'src/graphql/client';
import {
  GetAllStopStatusesRoute,
  Order,
  RefrigerationState,
} from 'src/graphql/types/delivery';
import {
  GDeliveryStatusDmp,
  GGetAllStopStatusesQuery,
  GLocationMembershipTier,
} from 'src/graphql/types/generated';
import { DeliveryState, RouteStatusState, StopStatusState } from './state';
import {
  doesStopHaveInfo,
  foundDeliveryNote,
  foundIssues,
  foundNotes,
} from 'src/utils/secondaryStopStatus';
import { isOutsideDeliveryWindow as isOrderOutsideDeliveryWindow } from 'src/utils/order';

export const getAllStopStatuses = createAsyncThunk(
  'delivery/getAllStopStatuses',
  async ({
    warehouseId,
    nightEnd,
  }: {
    warehouseId: string;
    nightEnd: string;
  }): Promise<GetAllStopStatusesRoute[]> => {
    const { data, error } = await client
      .query<GGetAllStopStatusesQuery>(
        GET_ALL_STOP_STATUSES,
        { input: { warehouseId, nightEnd } },
        { requestPolicy: 'cache-and-network' },
      )
      .toPromise();
    if (!data?.routes) {
      // TODO: do something with the error
      console.error('no GetAllStopStatusesRoutes returned', error);
      return [];
    }
    return data.routes;
  },
);

export const getAllStopStatusesFulfilled = (
  state: DeliveryState,
  action: PayloadAction<GetAllStopStatusesRoute[]>,
): void => {
  const routes = [...action.payload];
  let routeStatuses: RouteStatusState[] = [];
  let stopStatuses: StopStatusState[] = [];
  let redeliveryStopStatuses: StopStatusState[] = [];
  // eslint-disable-next-line complexity
  routes.forEach((route) => {
    const selectedWarehouse = state.warehouses?.find(
      (warehouse) => warehouse.id === state.selectedWarehouseId,
    );
    const stops = route.stops ?? [];
    const myStopStatuses: StopStatusState[] = stops.map((stop) => {
      const hasRepeatedAccessIssues =
        stop?.__typename === 'Delivery' ? stop.hasRepeatedAccessIssues : false;
      const isFirstDelivery =
        stop?.__typename === 'Delivery' ? stop.isFirstDelivery : false;
      const orderWithWarehouseNote = stop.orders?.some((order) => {
        return order.warehouseNote;
      });
      const myIsOutsideDeliveryWindow = stop.orders?.some((order) => {
        return isOrderOutsideDeliveryWindow({
          order: order as Order,
          timeZone: selectedWarehouse?.timeZone,
          viewingNightEnd: state.nightEnd.viewing,
        });
      });
      const hasPastryOrders: boolean =
        (stop?.__typename === 'Delivery' &&
          stop?.orders?.some((order) => {
            return !order.vendor.isWarehouse;
          })) ??
        false;
      const hasRefrigeratedOrderLines: boolean =
        (stop?.__typename === 'Delivery' &&
          stop?.orders?.some((order) => {
            return order.orderLines?.some(
              // eslint-disable-next-line max-nested-callbacks
              (orderLines) =>
                orderLines.sku.refrigerationState === 'refrigerated',
            );
          })) ??
        false;
      const hasFrozenOrderLines: boolean =
        (stop?.__typename === 'Delivery' &&
          stop?.orders?.some((order) => {
            return order.orderLines?.some(
              // eslint-disable-next-line max-nested-callbacks
              (orderLines) =>
                orderLines.sku.refrigerationState === 'frozen_state',
            );
          })) ??
        false;
      const hasBienCuitOrder: boolean =
        (stop?.__typename === 'Delivery' &&
          stop?.orders?.some((order) => {
            return order.vendor.name === 'Bien Cuit';
          })) ??
        false;
      // get all vendors that are not item level tracked besides Bien Cuit to get the rest of the pastry orders
      const hasPastryOrder: boolean =
        (stop?.__typename === 'Delivery' &&
          stop?.orders?.some((order) => {
            return (
              order.vendor.name !== 'Bien Cuit' && !order.itemLevelTracking
            );
          })) ??
        false;
      return {
        routeId: route.id,
        stopId: stop.id,
        stopStatus: stop.deliveryStatusDmp,
        hasPastryOrders: hasPastryOrders,
        stopSecondaryStatus: {
          hasRepeatedIssues: hasRepeatedAccessIssues,
          hasIssues: foundIssues(stop),
          hasIssueNotes: foundNotes(stop),
          hasDeliveryNote: foundDeliveryNote(stop),
          hasWarehouseNote: orderWithWarehouseNote ?? false,
          hasReminder: !!stop.reminderMessage,
          isOutsideDeliveryWindow: myIsOutsideDeliveryWindow ?? false,
          isFirstDelivery: isFirstDelivery,
          isRefrigerationRequired: hasRefrigeratedOrderLines,
          isFreezerRequired: hasFrozenOrderLines,
          hasBienCuitOrder: hasBienCuitOrder,
          hasPastryOrder: hasPastryOrder,
        },
      };
    });

    const redeliveryStops = route.redeliveryStops ?? [];
    const myRedeliveryStopStatuses: StopStatusState[] = redeliveryStops.map(
      (redeliveryStop) => {
        const hasRepeatedAccessIssues = redeliveryStop.hasRepeatedAccessIssues;
        const hasPastryOrders: boolean =
          redeliveryStop?.orders?.some((order) => {
            return !order.vendor.isWarehouse;
          }) ?? false;
        const orderWithWarehouseNote =
          redeliveryStop.orders?.some((order) => {
            return order.warehouseNote;
          }) ?? false;
        const myIsOutsideDeliveryWindow = redeliveryStop.orders?.some(
          (order) => {
            return isOrderOutsideDeliveryWindow({
              order: order as Order,
              timeZone: selectedWarehouse?.timeZone,
              viewingNightEnd: state.nightEnd.viewing,
            });
          },
        );
        const hasRefrigeratedOrderLines: boolean =
          redeliveryStop.orders?.some((order) => {
            return order.orderLines?.some(
              // eslint-disable-next-line max-nested-callbacks
              (orderLines) =>
                orderLines.sku.refrigerationState ===
                RefrigerationState.refrigerated,
            );
          }) ?? false;
        const hasFrozenOrderLines: boolean =
          redeliveryStop.orders?.some((order) => {
            return order.orderLines?.some(
              // eslint-disable-next-line max-nested-callbacks
              (orderLines) =>
                orderLines.sku.refrigerationState === RefrigerationState.frozen,
            );
          }) ?? false;
        const hasBienCuitOrder: boolean =
          redeliveryStop?.orders?.some((order) => {
            return order.vendor.name === 'Bien Cuit';
          }) ?? false;
        // get all vendors that are not item level tracked besides Bien Cuit to get the rest of the pastry orders
        const hasPastryOrder: boolean =
          redeliveryStop?.orders?.some((order) => {
            return (
              order.vendor.name !== 'Bien Cuit' && !order.itemLevelTracking
            );
          }) ?? false;
        return {
          routeId: route.id,
          stopId: redeliveryStop.id,
          stopStatus: redeliveryStop.deliveryStatusDmp,
          hasPastryOrders: hasPastryOrders,
          stopSecondaryStatus: {
            hasDeliveryNote: foundDeliveryNote(redeliveryStop),
            hasIssues: foundIssues(redeliveryStop),
            hasIssueNotes: foundNotes(redeliveryStop),
            hasRepeatedIssues: hasRepeatedAccessIssues,
            hasWarehouseNote: orderWithWarehouseNote,
            hasReminder: !!redeliveryStop.reminderMessage,
            isOutsideDeliveryWindow: myIsOutsideDeliveryWindow ?? false,
            isFirstDelivery: false,
            isRefrigerationRequired: hasRefrigeratedOrderLines,
            isFreezerRequired: hasFrozenOrderLines,
            hasBienCuitOrder: hasBienCuitOrder,
            hasPastryOrder: hasPastryOrder,
          },
        };
      },
    );

    // Delivery stops
    const inTransitStops =
      myStopStatuses.filter(
        (myStopStatus) =>
          myStopStatus.stopStatus === GDeliveryStatusDmp.InTransit,
      ) ?? [];
    const partiallyDeliveredStops =
      myStopStatuses.filter(
        (myStopStatus) =>
          myStopStatus.stopStatus === GDeliveryStatusDmp.PartiallyDelivered,
      ) ?? [];
    const undeliveredStops =
      myStopStatuses.filter(
        (myStopStatus) =>
          myStopStatus.stopStatus === GDeliveryStatusDmp.Undelivered,
      ) ?? [];
    const goldStops =
      stops.filter((stop) => {
        if (stop.__typename === 'Delivery')
          return (
            stop.orders?.[0]?.location.membershipTier ===
            GLocationMembershipTier.Gold
          );
        return false;
      }) ?? [];
    const silverStops =
      stops.filter((stop) => {
        if (stop.__typename === 'Delivery')
          return (
            stop.orders?.[0]?.location.membershipTier ===
            GLocationMembershipTier.Silver
          );
        return false;
      }) ?? [];
    const doorDashStops =
      stops.filter((stop) => {
        if (stop.__typename === 'Delivery')
          return stop.orders?.[0]?.location.isDoordash;
        return false;
      }) ?? [];

    // Pick up stops
    const notPickedUpStops =
      myStopStatuses.filter(
        (myStopStatus) =>
          myStopStatus.stopStatus === GDeliveryStatusDmp.NotPickedUp,
      ) ?? [];
    const partiallyPickedUpStops =
      myStopStatuses.filter(
        (myStopStatus) =>
          myStopStatus.stopStatus === GDeliveryStatusDmp.PartiallyPickedUp,
      ) ?? [];

    // Redelivery stops
    const inTransitRedeliveryStops =
      myRedeliveryStopStatuses.filter(
        (myStopStatus) =>
          myStopStatus.stopStatus === GDeliveryStatusDmp.InTransit,
      ) ?? [];
    const partiallyDeliveredRedeliveryStops =
      myRedeliveryStopStatuses.filter(
        (myStopStatus) =>
          myStopStatus.stopStatus === GDeliveryStatusDmp.PartiallyDelivered,
      ) ?? [];
    const undeliveredRedeliveryStops =
      myRedeliveryStopStatuses.filter(
        (myStopStatus) =>
          myStopStatus.stopStatus === GDeliveryStatusDmp.Undelivered,
      ) ?? [];
    const goldRedeliveryStops =
      redeliveryStops.filter((stop) => {
        return (
          stop.orders?.[0]?.location.membershipTier ===
          GLocationMembershipTier.Gold
        );
      }) ?? [];
    const silverRedeliveryStops =
      redeliveryStops.filter((stop) => {
        return (
          stop.orders?.[0]?.location.membershipTier ===
          GLocationMembershipTier.Silver
        );
      }) ?? [];
    const doorDashRedeliveryStops =
      redeliveryStops.filter((stop) => {
        return stop.orders?.[0]?.location.isDoordash;
      }) ?? [];

    const hasStopWithInfo =
      doesStopHaveInfo(myStopStatuses) ||
      doesStopHaveInfo(myRedeliveryStopStatuses);

    // refrigeration state stops
    const refrigeratedStops =
      myStopStatuses.filter(
        (myStopStatus) =>
          myStopStatus.stopSecondaryStatus.isRefrigerationRequired,
      ) ?? [];
    const frozenStops =
      myStopStatuses.filter(
        (myStopStatus) => myStopStatus.stopSecondaryStatus.isFreezerRequired,
      ) ?? [];
    const bienCuitStops =
      myStopStatuses.filter(
        (myStopStatus) => myStopStatus.stopSecondaryStatus.hasBienCuitOrder,
      ) ?? [];
    const pastryStops =
      myStopStatuses.filter(
        (myStopStatus) => myStopStatus.stopSecondaryStatus.hasPastryOrder,
      ) ?? [];

    const totalStops = myStopStatuses.length + myRedeliveryStopStatuses.length;
    const myRouteStatus = {
      routeId: route.id,
      totalStops,
      totalStopsVisited:
        totalStops - inTransitStops.length - inTransitRedeliveryStops.length,
      hasSecondaryStatuses: hasStopWithInfo,
      totalUndeliveredStops:
        undeliveredStops.length + undeliveredRedeliveryStops.length,
      totalPartiallyDeliveredStops:
        partiallyDeliveredStops.length +
        partiallyDeliveredRedeliveryStops.length,
      totalNotPickedUpStops: notPickedUpStops.length,
      totalPartiallyPickedUpStops: partiallyPickedUpStops.length,
      totalGoldMembers: goldStops.length + goldRedeliveryStops.length,
      totalSilverMembers: silverStops.length + silverRedeliveryStops.length,
      totalDoorDashStops: doorDashStops.length + doorDashRedeliveryStops.length,
      totalRefrigerationRequired: refrigeratedStops.length,
      totalFreezerRequired: frozenStops.length,
      totalBienCuitStops: bienCuitStops.length,
      totalPastryStops: pastryStops.length,
    };

    routeStatuses.push(myRouteStatus);
    stopStatuses = stopStatuses.concat(myStopStatuses);
    redeliveryStopStatuses = redeliveryStopStatuses.concat(
      myRedeliveryStopStatuses,
    );
  });

  state.deliveryStatuses = {
    routeStatuses,
    stopStatuses,
    redeliveryStopStatuses,
    loading: false,
  };
};

export const getAllStopStatusesPending = (state: DeliveryState): void => {
  const hasCache = state.deliveryStatuses.routeStatuses.length > 0;
  state.deliveryStatuses.loading = hasCache ? false : true;
};

export const getAllStopStatusesRejected = (state: DeliveryState): void => {
  state.deliveryStatuses.loading = false;
  // TODO
};
