import { ReactNode } from 'react';
import { keyframes } from '@emotion/react';
import { ThemeUICSSObject } from 'theme-ui';

type FadeProps = {
  toggleAnimation?: boolean;
  children: ReactNode;
  id?: string;
  exitDirection?: string;
  animationSpeed?: number;
  style?: ThemeUICSSObject;
};

const Fade = ({
  toggleAnimation,
  animationSpeed = 0.5,
  id,
  exitDirection = 'right',
  children,
  style,
}: FadeProps): JSX.Element => {
  if (toggleAnimation === undefined)
    return (
      <div id={id} sx={style}>
        {children}
      </div>
    );

  const keyFrameFadeIn = keyframes`
    0%    { opacity: 0; transform: translateX(${
      exitDirection === 'right' ? '80px' : '-80px'
    });}
    100%  { opacity: 1;}
  `;

  const keyFrameFadeOut = keyframes`
    0%    { opacity: 1;}
    100%  { opacity: 0; transform: translateX(${
      exitDirection === 'right' ? '80px' : '-80px'
    });}
  `;

  const fadeIn: ThemeUICSSObject = {
    animation: `${keyFrameFadeIn} ${animationSpeed}s forwards`,
  };

  const fadeOut: ThemeUICSSObject = {
    animation: `${keyFrameFadeOut} ${animationSpeed}s forwards`,
  };

  const fadeStyle = toggleAnimation ? fadeIn : fadeOut;

  return (
    <div
      id={id}
      className={toggleAnimation ? 'fadeIn' : 'fadeOut'}
      sx={{ ...fadeStyle, ...style }}
    >
      {children}
    </div>
  );
};

export default Fade;
