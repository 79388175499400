import { ThemeUICSSObject } from 'theme-ui';

export const toastCard: ThemeUICSSObject = {
  position: 'fixed',
  top: 0,
  right: 0,
  backgroundColor: 'white',
  zIndex: 100,
  margin: ['180px 10px 5vw 6vw', '130px 20px 3vw 6vw'],
  borderRadius: '8px',
  padding: '16px',
  minHeight: '64px',
  boxShadow: 'bold',
  width: ['60vw', '360px'],
  minWidth: '260px',
};

export const innerCard: ThemeUICSSObject = {
  display: 'flex',
  justifyContent: 'space-between',
};

export const colLeft: ThemeUICSSObject = { display: 'flex' };

export const body: ThemeUICSSObject = { marginLeft: '11px', marginTop: '2px' };

export const header: ThemeUICSSObject = {
  fontFamily: 'heading',
  fontSize: 1,
};

export const msg: ThemeUICSSObject = {
  fontFamily: 'body',
  fontSize: 1,
  color: 'secondaryText',
  marginTop: '5px',
};

export const exit: ThemeUICSSObject = {
  width: '12px',
  height: '12px',
  '&:hover': {
    cursor: 'pointer',
  },
};

export const linkStyle: ThemeUICSSObject = {
  fontWeight: 'bold',
  color: 'text',
  marginLeft: '4px',
};

export const underlineStyle: ThemeUICSSObject = {
  textDecoration: 'underline',
  cursor: 'pointer',
};
