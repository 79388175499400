import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { client } from 'src/graphql/client';
import { UPDATE_ROUTING_IMPORT_PLAN_TRUCK_COUNT } from 'src/graphql/requests/mutations';
import { RouteImportPlan } from 'src/graphql/types/delivery';
import {
  GRoutingImportPlanTruckCountUpdateInput,
  GUpdateRoutingImportPlanTruckCountMutation,
} from 'src/graphql/types/generated';
import { getErrorCode, isUserErrorInArray, ON_CALL_URL } from 'src/utils';
import { closeSecondaryCards, setIsUpdateTruckMutationLoading } from '.';
import { hideModal, setIsModalLoading } from '../modal';
import { Link, NotificationType, showNotification } from '../notification';
import { getRouteImportInfo } from './getRouteImportInfo';
import { DeliveryState } from './state';

const ROUTING_IMPORT_PLAN_NOT_FOUND = 'TruckCountInvalid';
const ROUTING_IMPORT_PLAN_NOT_FOUND_MSG =
  'You have entered an invalid truck count, please submit an on-call request ';

const mutationComplete = ({
  thunkAPI,
  isSuccess,
  message,
  link,
  errorCode,
}: {
  thunkAPI: any;
  isSuccess: boolean;
  message?: string;
  link?: Link;
  errorCode?: string;
}): void => {
  thunkAPI.dispatch(hideModal());
  thunkAPI.dispatch(setIsModalLoading(false));
  thunkAPI.dispatch(setIsUpdateTruckMutationLoading(false));

  thunkAPI.dispatch(
    showNotification({
      type: isSuccess ? NotificationType.Success : NotificationType.Error,
      message,
      link,
      errorCode,
    }),
  );
};

export const updateTruckCount = createAsyncThunk(
  'delivery/updateTruckCount',
  async (
    input: GRoutingImportPlanTruckCountUpdateInput,
    thunkAPI,
  ): Promise<RouteImportPlan | null> => {
    thunkAPI.dispatch(setIsModalLoading(true));
    thunkAPI.dispatch(setIsUpdateTruckMutationLoading(true));
    const { data, error } = await client
      .mutation<GUpdateRoutingImportPlanTruckCountMutation>(
        UPDATE_ROUTING_IMPORT_PLAN_TRUCK_COUNT,
        { input },
      )
      .toPromise();

    // mutation to delivery service failed
    if (
      error ||
      !data?.updateRoutingImportPlanTruckCount?.routingImportPlan ||
      data.updateRoutingImportPlanTruckCount.userErrors.length > 0
    ) {
      const errorCode = getErrorCode({
        error,
        expectedData:
          data?.updateRoutingImportPlanTruckCount?.routingImportPlan,
        userErrors: data?.updateRoutingImportPlanTruckCount?.userErrors,
      });
      const isImportPlanNotFound = isUserErrorInArray(
        ROUTING_IMPORT_PLAN_NOT_FOUND,
        data?.updateRoutingImportPlanTruckCount?.userErrors ?? [],
      );
      const message = isImportPlanNotFound
        ? ROUTING_IMPORT_PLAN_NOT_FOUND_MSG
        : undefined;
      const link = isImportPlanNotFound
        ? { url: ON_CALL_URL, label: 'here.' }
        : undefined;

      mutationComplete({
        thunkAPI,
        isSuccess: false,
        message,
        link,
        errorCode: errorCode,
      });
      console.warn(
        'updateRoutingImportPlanTruckCount mutation to delivery service failed',
      );
      return null;
    }

    mutationComplete({
      thunkAPI,
      isSuccess: true,
      message: 'Truck count updated successfully!',
    });
    thunkAPI.dispatch(closeSecondaryCards());
    thunkAPI.dispatch(getRouteImportInfo({ viewingNightEnd: input.nightEnd }));
    return {
      ...data.updateRoutingImportPlanTruckCount.routingImportPlan,
      warehouseId: input.warehouseId,
    };
  },
);

export const updateTruckCountFulfillment = (
  state: DeliveryState,
  action: PayloadAction<RouteImportPlan | null>,
): void => {
  const currentPlansState = state.routeImport.plans
    ? [...state.routeImport.plans]
    : undefined;

  if (currentPlansState && action.payload) {
    const index = currentPlansState.findIndex(
      (plan) => plan.warehouseId === action.payload?.warehouseId,
    );
    currentPlansState.splice(index, 1, {
      ...currentPlansState[index],
      ...action.payload,
    });
    state.routeImport.plans = currentPlansState;
    state.routeImport.warehouseIdEditing = undefined;
  }
};
