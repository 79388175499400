import { useEffect, useState } from 'react';
import {
  LoaderTable,
  Table,
  TableBody,
  TableHeader,
} from 'src/components/Table';

const Loader = ({ labels }: { labels: string[] }): JSX.Element => {
  const [loadingMsg, setLoadingMsg] = useState('');
  const [counter, setCounter] = useState(0);
  const loaderMessages = [
    'Loading all locations can take some time...',
    'We appreciate you patience...',
    'Wow you have a lot of locations...',
  ];

  useEffect(() => {
    const timerId = setInterval(() => {
      setLoadingMsg(loaderMessages[counter % 3]);
      setCounter(counter + 1);
    }, 10000);

    return () => clearInterval(timerId);
  });

  return (
    <>
      {loadingMsg !== '' && (
        <div sx={{ variant: 'text.paragraphLgDefault', marginBottom: '6px' }}>
          {loadingMsg}
        </div>
      )}
      <Table data-test-id="locations-table">
        <TableHeader labels={labels} />
        <TableBody>
          <LoaderTable numColumns={8} />
        </TableBody>
      </Table>
    </>
  );
};

export default Loader;
