import {
  Delivery,
  Warehouse,
  GetStopAddress,
  PickUp,
  StopType,
  UserError,
  Redelivery,
  Route,
} from '../graphql/types/delivery';
import config from '../config';
import { CombinedError } from 'urql';

export const CANNOT_CHANGE_DATA =
  'You cannot edit data in previous cut off windows.';
export const DISABLED_TOOLTIP_RUNNING =
  'You cannot edit details while the routing calculations are being performed.';
export const CANNOT_CHANGE_DEFINED_TRUCK_COUNT =
  'You cannot edit truck cut past cut off. To edit truck count past cut off please reach out to engineering.';

export const ON_CALL_URL =
  'https://forms.monday.com/forms/f4dd11b07f0200b827605e514c1db3a4?r=use1';

export const getTooltip = (
  routeImportRunning: boolean,
  isPastDate?: boolean,
  isTruckCountDefinedPresent?: boolean,
): string | undefined => {
  if (routeImportRunning) return DISABLED_TOOLTIP_RUNNING;
  if (isPastDate) return CANNOT_CHANGE_DATA;
  if (isTruckCountDefinedPresent) return CANNOT_CHANGE_DEFINED_TRUCK_COUNT;
  return undefined;
};

export const LOBBY_ROUTE_COLOR = '#000000';
export const INVALID_NIGHT_END = 'InvalidNightEnd';

export const capitalize = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const naturalSort = (a = '', b = ''): number =>
  a.toString().localeCompare(b, navigator.languages[0] || navigator.language, {
    numeric: true,
    ignorePunctuation: true,
  });

export const truncateString = (
  str: string,
  maxLength: number,
): { truncatedStr: string; truncated: boolean } => {
  const truncated = str?.length > maxLength;
  return {
    truncatedStr: truncated ? str.substr(0, maxLength - 1) + '...' : str,
    truncated,
  };
};

export const isDefined = (x: unknown): boolean => x !== undefined && x !== null;

export const addressString = (address: {
  addressLine1: string;
  addressLine2?: string;
  city: string;
  postalCode: string;
  state: string;
}): string => {
  const addLine2 = address?.addressLine2 ? ` ${address?.addressLine2}, ` : ', ';
  const addressStr = `${address?.addressLine1}${addLine2}${address?.city} ${address?.state} ${address?.postalCode}`;
  return addressStr;
};

export const getSelectedWarehouse = (
  id?: string,
  warehouses?: Warehouse[],
): Warehouse | undefined => {
  if (id && warehouses) {
    return warehouses.find((warehouse) => warehouse.id === id);
  }
  return undefined;
};

export const findStopName = (
  stop: Delivery | PickUp | Redelivery,
  selectedWarehouseId?: string,
): string => {
  const typename = stop.__typename;
  let stopName;
  switch (typename) {
    case StopType.Delivery:
    case StopType.Redelivery:
      stopName = stop.location?.businessName ?? 'Unknown Location';
      break;
    case StopType.PickUp:
      stopName = stop.vendor.name ?? 'Unknown Vendor';
      break;
    default:
      stopName = 'Unknown Stop';
      break;
  }
  // Add state code before stop name. Just for EWR1.
  let state = '';
  if (selectedWarehouseId === getEWR1Id()) {
    const stopState =
      stop.__typename === 'PickUp'
        ? stop.vendor.address?.state
        : stop.location?.address.state;
    if (stopState === 'NY' || stopState === 'New York') state = 'NY: ';
    if (stopState === 'NJ' || stopState === 'New Jersey') state = 'NJ: ';
  }

  return `${state}${stopName}`;
};

export type Coords = { lat: number; lng: number };

export const getStopCoords = (
  stop?: Delivery | PickUp | Redelivery,
): Coords | undefined => {
  const address =
    stop?.__typename === 'PickUp'
      ? stop?.vendor.address
      : stop?.location?.address;

  if (!address?.latitude || !address?.longitude) {
    return undefined;
  }

  return {
    lat: address.latitude,
    lng: address.longitude,
  };
};

export const isUserErrorInArray = (
  errorTypeName: string,
  userErrors?: any,
): boolean =>
  userErrors?.find(
    (userError: { __typename: string }) =>
      userError.__typename === errorTypeName,
  ) !== undefined;

export const getEWR1Id = (): string => {
  const env = config().environment;
  switch (env) {
    case 'development':
    case 'stage-1':
    case 'stage-2':
    case 'stage':
      return '7215746f-04bb-4616-b586-e28e62cc73bb';
    case 'prod':
    case 'prod-1':
    default:
      return 'a7e01b5e-6576-49d5-a9d0-b73bf5be2768';
  }
};

export const getNYC1Id = (): string => {
  const env = config().environment;
  switch (env) {
    case 'development':
    case 'stage-1':
    case 'stage-2':
    case 'stage':
      return 'ba7f49a3-7a9d-4641-9373-99d518069bc5';
    case 'prod':
    case 'prod-1':
    default:
      return '5178c94a-d8d7-4ea4-9280-07c5cd3ad4db';
  }
};

export const getErrorCode = ({
  error,
  expectedData,
  userErrors,
}: {
  error?: CombinedError;
  expectedData?: any;
  userErrors?: UserError[];
}): string => {
  if (error) {
    return 'GraphQLError';
  }
  if (userErrors) {
    let combinedErrors = '';
    userErrors.forEach((userError) => {
      combinedErrors += `${userError.__typename} `;
    });
    return combinedErrors;
  }
  if (!expectedData) {
    return 'NoDataReturned';
  }
  return 'UnknownError';
};

export const updateSequenceNumbersRedelivery = (route: Route): Route => {
  const updatedRedeliveryStops = route.redeliveryStops.map((redeliveryStop) => {
    const location = redeliveryStop.location
      ? {
          ...redeliveryStop.location,
          businessName: `Re: ${redeliveryStop.location.businessName}`,
        }
      : undefined;
    return {
      ...redeliveryStop,
      sequenceNumber: (
        Number(redeliveryStop.sequenceNumber ?? '0') +
        (route.stops?.length ?? 0)
      ).toString(),
      location,
    };
  });
  return {
    ...route,
    redeliveryStops: updatedRedeliveryStops,
  };
};

export const decodeSearchParams = (searchParams: any): any => {
  const entries = new Map(searchParams.entries());
  return Object.fromEntries(entries);
};

export const getStopTotals = (
  routes: Route[],
): {
  totalPickUpStops: number;
  totalDeliveryStops: number;
  totalRedeliveryStops: number;
  totalStops: number;
} => {
  let totalPickUpStops = 0;
  let totalDeliveryStops = 0;
  let totalRedeliveryStops = 0;
  routes.forEach((route) => {
    const deliveryStops = (route.stops ?? []).filter(
      (stop) => stop.__typename === 'Delivery',
    );
    const pickUpStops = (route.stops ?? []).filter(
      (stop) => stop.__typename === 'PickUp',
    );
    const redeliveryStops = route.redeliveryStops ?? [];
    totalDeliveryStops = (deliveryStops.length ?? 0) + totalDeliveryStops;
    totalPickUpStops = (pickUpStops.length ?? 0) + totalPickUpStops;
    totalRedeliveryStops = (redeliveryStops.length ?? 0) + totalRedeliveryStops;
  });
  return {
    totalDeliveryStops,
    totalPickUpStops,
    totalRedeliveryStops,
    totalStops: totalPickUpStops + totalDeliveryStops + totalRedeliveryStops,
  };
};

export const getPercent = (numerator: number, denominator: number): number =>
  Math.ceil((numerator / denominator) * 100);
