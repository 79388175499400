import { ThemeUIStyleObject } from 'theme-ui';

export const page: ThemeUIStyleObject = {
  position: 'absolute',
  width: '100vw',
  height: '100vh',
  display: 'flex',
  zIndex: 99,
};

export const menuContainerStyle: ThemeUIStyleObject = {
  backgroundColor: 'white',
  zIndex: 100,
  borderRightStyle: 'solid',
  borderRightColor: 'backgroundMedium',
  borderRightWidth: '1px',
  padding: '24px 12px',
  width: '324px',
  height: '100vh',
  boxShadow: 'standard',
  display: 'flex',
};

export const notMenuStyle: ThemeUIStyleObject = {
  width: 'calc(100% - 324px)',
  height: '100%',
};

export const innerContainerStyle: ThemeUIStyleObject = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '100%',
};

export const headerContainerStyle: ThemeUIStyleObject = {
  display: 'flex',
  marginBottom: '24px',
  marginRight: '10px',
};

export const header: ThemeUIStyleObject = {
  fontFamily: 'heading',
  fontSize: '16px',
  marginLeft: '8px',
  marginTop: '3px',
};

export const navLinkContainer: ThemeUIStyleObject = {
  width: '100%',
  display: 'flex',
  cursor: 'pointer',
  padding: '12px',
  borderRadius: 2,
  '&:hover': {
    backgroundColor: 'backgroundMedium',
  },
  color: 'black',
  textDecoration: 'none',
};

export const navLinkLabel: ThemeUIStyleObject = {
  marginLeft: '12px',
  marginTop: '4px',
  fontFamily: 'body',
  fontSize: '14px',
};

export const helpLinkLabel: ThemeUIStyleObject = {
  marginLeft: '12px',
  marginTop: '2px',
  fontFamily: 'body',
  fontSize: '14px',
  color: 'text',
};

export const exitContainer: ThemeUIStyleObject = {
  padding: '3px 15px 3px 20px',
  cursor: 'pointer',
  height: '30px',
};

export const issuesSymbol = { marginX: '5px' };

export const warehouseOperationsSymbol = { marginX: '2px' };
