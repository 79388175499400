import { GDeliveryStatusDmp } from 'src/graphql/types/generated';

export type displayedStop = {
  id: string;
  isChecked: boolean;
  stopStatus: GDeliveryStatusDmp;
  redeliveryStopId: string | undefined;
  redeliveryStopStatus: string | undefined;
  loading: boolean;
};

export type SlackIssues = {
  accessIssues: SlackIssue[];
  damagedIssues: SlackIssue[];
  otherIssues: SlackIssue[];
};

export type SlackIssue = {
  stopId: string;
  issueMsg: string;
};

export type IssuesState = {
  filters: {
    displayInTransitStops: boolean;
    displayDeliveredStops: boolean;
    displayUndeliveredStops: boolean;
    displayPartiallyDeliveredStops: boolean;
  };
  displayedStops: displayedStop[];
  slackIssues: SlackIssues;
};

export const initialState: IssuesState = {
  filters: {
    displayInTransitStops: false,
    displayDeliveredStops: true,
    displayUndeliveredStops: true,
    displayPartiallyDeliveredStops: true,
  },
  displayedStops: [] as displayedStop[],
  slackIssues: {
    accessIssues: [],
    damagedIssues: [],
    otherIssues: [],
  },
};
