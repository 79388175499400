import { ReactNode } from 'react';
import { ThemeUICSSObject } from 'theme-ui';
import { mapCard, mapCardSecondary } from './styles';

export const SecondaryMapCard = ({
  style,
  children,
  id,
}: {
  style?: ThemeUICSSObject;
  children: ReactNode;
  id?: string;
}): JSX.Element => {
  return (
    <div sx={{ ...mapCard, ...mapCardSecondary, ...style }} id={id}>
      {children}
    </div>
  );
};
