import { IconCheckedSquare, IconIncomplete } from '@odekoteam/doppio';

const CompletionIndicator = ({
  completed,
  id,
}: {
  completed: boolean;
  id?: string;
}): JSX.Element => {
  return (
    <span id={id} sx={{ width: '22px', height: '22px' }}>
      {completed ? <IconCheckedSquare /> : <IconIncomplete color="border" />}
    </span>
  );
};

export default CompletionIndicator;
