import { Flex } from 'theme-ui';
import { Page, View, Document, StyleSheet } from '@react-pdf/renderer';
import { pageContainer } from '../styles';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { authSelector } from 'src/state/selectors/auth';
import { useEffect } from 'react';
import analytics from 'src/services/analytics';
import { loginPath } from 'src/components/Navbar';
import {
  routesLoadingSelector,
  routesSelector,
} from 'src/state/selectors/delivery';
import { getRoutes } from 'src/state/slices/delivery';
import { clearRouteQuantities } from 'src/state/slices/fulfillmentDeltas';
import FulfillmentTable from '../../components/FulfillmentDeltas/FulfillmentTable';

const FulfillmentDeltasPrintable = (): JSX.Element => {
  const { warehouseId, nightEnd } = useParams();
  const navigate = useNavigate();
  const { isUserAuthenticated } = useAppSelector(authSelector);
  const dispatch = useAppDispatch();
  const routes = useAppSelector(routesSelector);
  const routesLoading = useAppSelector(routesLoadingSelector);

  useEffect(() => {
    analytics.track(`"Issues" Page Visit`);
  }, []);

  useEffect(() => {
    if (!isUserAuthenticated) {
      navigate(loginPath);
    }
  }, [isUserAuthenticated, navigate]);

  useEffect(() => {
    dispatch(clearRouteQuantities());
    if (!routes && warehouseId && nightEnd) {
      dispatch(
        getRoutes({
          warehouseId: warehouseId,
          nightEnd: nightEnd,
        }),
      );
    }
  }, []);

  return (
    <Document>
      <Page size="LETTER" style={styles.page} id="page">
        <Flex id="fulfillmentDeltas" sx={pageContainer}>
          {(routesLoading || !routes) && (
            <Flex sx={{ fontFamily: 'body', justifyContent: 'center' }}>
              Loading...
            </Flex>
          )}
          {routes && <FulfillmentTable routes={routes} printable />}
        </Flex>
      </Page>
    </Document>
  );
};

// Create styles
const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    marginLeft: '24px',
  },
});

export default FulfillmentDeltasPrintable;
