import { Flex, ThemeUICSSObject } from 'theme-ui';
import { Tooltip } from '@odekoteam/doppio';
import DoorDashSvg from 'assets/doorDash.svg';

const background: ThemeUICSSObject = {
  padding: '4px',
  borderRadius: '4px',
  alignItems: 'center',
  backgroundColor: 'backgroundMedium',
};

const countStyle: ThemeUICSSObject = {
  marginLeft: '5px',
  variant: 'text.labelSm',
  color: 'secondaryText',
};

const DoorDashIcon = ({
  count,
  style = {},
  testId,
}: {
  count?: number;
  style?: ThemeUICSSObject;
  testId?: string;
}): JSX.Element | null => {
  if (count === 0) return null;

  return (
    <Tooltip labelText="DoorDash customer" placement="bottom">
      <Flex
        data-test-id={`doordash-icon-${testId}`}
        sx={{ ...(count ? background : {}), ...style }}
      >
        <DoorDashSvg />
        {count && <span sx={countStyle}>{count}</span>}
      </Flex>
    </Tooltip>
  );
};

export default DoorDashIcon;
