import ReactCalendar from 'react-calendar';
import prevArrow from '../../../assets/prevArrow.png';
import nextArrow from '../../../assets/nextArrow.png';
import { calendarContainer, arrowStyle, calendarStyle } from './styles';

import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { Dispatch, SetStateAction } from 'react';
import { nightEndSelector } from 'src/state/selectors/delivery';
import { setNightEnd } from 'src/state/slices/delivery';
import { adjustForTimezone, getYearMonthDay, addDays } from 'src/utils/date';
import { useSearchParams } from 'react-router-dom';
import { ThemeUICSSObject } from 'theme-ui';
import { Value } from 'react-calendar/dist/cjs/shared/types';
import { decodeSearchParams } from 'src/utils';

const Calendar = ({
  setIsCalendarVisible,
  isMobileViewport,
  styles = [{}, {}],
}: {
  setIsCalendarVisible: Dispatch<SetStateAction<boolean>>;
  isMobileViewport: boolean;
  styles?: ThemeUICSSObject[];
}): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const nightEnd = useAppSelector(nightEndSelector);
  const [searchParams, setSearchParams] = useSearchParams();
  const mobileStyles = isMobileViewport
    ? { top: '170px', right: '18px', ...styles[0] }
    : {};

  if (!nightEnd.current || !nightEnd.viewing) return null;
  const viewingDate = adjustForTimezone(new Date(nightEnd.viewing));
  const maxDate = adjustForTimezone(addDays(7, new Date(nightEnd.current)));

  const onChangeSelectedDate = (newDate: Value): void => {
    const myDate = getYearMonthDay(newDate as Date);
    const params = decodeSearchParams(searchParams);
    setSearchParams({ ...params, date: myDate });
    dispatch(setNightEnd(myDate));
    setIsCalendarVisible(false);
  };

  return (
    <div sx={{ ...calendarContainer, ...styles[1], ...mobileStyles }}>
      <ReactCalendar
        sx={calendarStyle}
        onChange={onChangeSelectedDate}
        value={viewingDate}
        next2Label={null}
        prev2Label={null}
        calendarType="US"
        prevLabel={<img src={prevArrow} alt="prev-arrow" sx={arrowStyle} />}
        nextLabel={<img src={nextArrow} alt="next-arrow" sx={arrowStyle} />}
        maxDate={maxDate}
        formatMonthYear={(locale, date): string => {
          return date.toLocaleString('default', {
            month: 'short',
            year: 'numeric',
          });
        }}
        formatShortWeekday={(locale, date): string => {
          return date.toLocaleString('default', {
            weekday: 'narrow',
          });
        }}
      />
    </div>
  );
};
export default Calendar;
