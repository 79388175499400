import { gql } from 'urql';
import { PRODUCT } from '../client';

export const GET_LOCATIONS = gql`
  query ${PRODUCT}GetLocations($warehouseId: ID!) {
    warehouse(warehouseId: $warehouseId) {
      locations {
        id
        keyId
        businessName
        membershipTier
        address {
          city
          state
          postalCode
        }
        includeInAutomatedRouting
        isDoordash
        locationDeliveryWindow {
          startTime
          endTime
        }
      }
    }
  }
`;
