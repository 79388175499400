import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OperationsState, initialState, SortByFilterValues } from './state';

export const operationsSlice = createSlice({
  name: 'operations',
  initialState,
  reducers: {
    setSelectedRouteId: (
      state: OperationsState,
      action: PayloadAction<string | undefined>,
    ) => {
      return {
        ...state,
        selectedRouteId: action.payload,
      };
    },
    setSortByFilterValue: (
      state: OperationsState,
      action: PayloadAction<SortByFilterValues>,
    ) => {
      const sortByFilterValue = action.payload;
      return {
        ...state,
        sortByFilterValue: sortByFilterValue,
      };
    },
  },
});
