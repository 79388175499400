import { gql } from 'urql';
import { PRODUCT } from '../client';

export const GET_DELIVERY_PHOTOS = gql`
  query ${PRODUCT}GetDeliveryPhotos($input: StopInput!) {
    stop(input: $input) {
      ... on Delivery {
        attachments {
          id
          url
          attachmentCategory
        }
      }
    }
  }
`;
