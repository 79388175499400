import { Spacer } from '@odekoteam/doppio';
import { Flex } from 'theme-ui';
import NewTabLink from './NewTabLink';
import config from '../config';

const OrderLinks = ({
  netsuiteUrl,
  orderId,
  flexDirection = 'row',
  locationId,
}: {
  netsuiteUrl?: string;
  orderId: string;
  flexDirection?: 'row' | 'column';
  locationId?: string;
}): JSX.Element | null => {
  const { so_api_url: soApiUrl, portal_url: portalUrl } = config();
  const fullSoApiUrl = `${soApiUrl}/manage/orders/${orderId}`;
  const fullPortalUrl = `${portalUrl}/supply/locations/${locationId}/lg-orders/orderDetails/${orderId}`;

  return (
    <Flex sx={{ flexDirection, gap: '8px' }}>
      <div>
        <NewTabLink url={fullSoApiUrl} id="soapi-order-link">
          soAPI Order
        </NewTabLink>
      </div>
      {netsuiteUrl && (
        <div>
          <NewTabLink url={netsuiteUrl} id="netsuite-order-link">
            Netsuite Order
          </NewTabLink>
        </div>
      )}
      {locationId && (
        <div>
          <NewTabLink url={fullPortalUrl} id="portal-order-link">
            Portal Order
          </NewTabLink>
        </div>
      )}
    </Flex>
  );
};

export default OrderLinks;
