import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum ModalType {
  Confirmation = 'Confirmation',
  CreateRoute = 'CreateRoute',
  Fullscreen = 'Fullscreen',
  UpdateTruckCount = 'UpdateTruckCount',
  AutomatedRoutingConfirmation = 'AutomatedRoutingConfirmation',
  UpdateBulkItems = 'UpdateBulkItemsModal',
}

export type ModalState = {
  type: ModalType;
  isLoading: boolean;
  isVisible?: boolean;
  message?: string;
  dismissButtonLabel?: string;
  variables?: any;
};

const initialState: ModalState = {
  isVisible: false,
  isLoading: false,
  type: ModalType.Confirmation,
  message: undefined,
  dismissButtonLabel: 'Okay',
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    showModal: (
      state: ModalState,
      action: PayloadAction<{
        type: ModalType;
        message?: string;
        dismissButtonLabel?: string;
        variables?: any;
      }>,
    ) => {
      return {
        ...state,
        isVisible: true,
        type: action.payload.type,
        message: action.payload.message,
        dismissButtonLabel:
          action.payload.dismissButtonLabel ?? initialState.dismissButtonLabel,
        variables: action.payload.variables,
      };
    },
    hideModal: (state: ModalState) => {
      return {
        ...state,
        isVisible: false,
        variables: undefined,
      };
    },
    setIsModalLoading: (state: ModalState, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isLoading: action.payload,
      };
    },
  },
});

const { reducer, actions } = modalSlice;
export const { showModal, hideModal, setIsModalLoading } = actions;
export default reducer;
