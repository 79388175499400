import { useAppDispatch } from 'src/hooks/store';
import { expandNav } from 'src/state/slices/app';
import { IconHamburgerMenu } from '@odekoteam/doppio';

const NavHamburger = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const handleClick = (): void => {
    dispatch(expandNav());
  };

  return (
    <div sx={{ paddingTop: '33px' }}>
      <span
        id="navHamburger"
        sx={{
          padding: '6px 17px',
          marginTop: '-12px',
          display: 'inline-flex',
          cursor: 'pointer',
        }}
        onClick={handleClick}
      >
        <IconHamburgerMenu width={24} height={24} />
      </span>
    </div>
  );
};

export default NavHamburger;
