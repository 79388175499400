import { ReactNode } from 'react';

const NewTabLink = ({
  id,
  url,
  children,
  style,
}: {
  id: string;
  url: string;
  children: ReactNode;
  style?: React.CSSProperties;
}): JSX.Element | null => {
  return (
    <a
      id={id}
      sx={{ ...style, variant: 'text.link' }}
      target="_blank"
      rel="noopener noreferrer"
      href={url}
    >
      {children}
    </a>
  );
};

export default NewTabLink;
