import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { Button, Spacer } from '@odekoteam/doppio';
import { modalSelector } from 'src/state/selectors/modal';
import { updateTruckCount } from 'src/state/slices/delivery/updateTruckCount';
import CancelButton from './CancelButton';

const UpdateTruckCountButton = (): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const { variables } = useAppSelector(modalSelector);

  const handleAction = (): void => {
    if (variables) {
      dispatch(updateTruckCount(variables));
    }
  };

  return (
    <>
      <Button
        id="modal-create-route-action-button"
        variant="secondary"
        onPress={handleAction}
      >
        Yes
      </Button>
      <Spacer width="$1" />
      <CancelButton />
    </>
  );
};
export default UpdateTruckCountButton;
