import { GetLocation } from 'src/graphql/types/delivery';
import { naturalSort } from 'src/utils';
import { isSameDayDeliveryWindow } from 'src/utils/date';

export enum RoutingFilter {
  allLocations = 'allLocations',
  automaticallyRouted = 'automaticallyRouted',
  manuallyRouted = 'manuallyRouted',
}

export enum DeliveryWindowFilter {
  allWindows = 'allWindows',
  nightTime = 'nightTime',
  dayTime = 'dayTime',
}

export type Item = {
  value: string;
  label: string;
};

export const alphabeticalByName = (a: GetLocation, b: GetLocation): number =>
  naturalSort(a.businessName, b.businessName);

export const routingFilterItems = [
  { label: 'All Locations', value: RoutingFilter.allLocations },
  { label: 'Automatically Routed', value: RoutingFilter.automaticallyRouted },
  { label: 'Manually Routed', value: RoutingFilter.manuallyRouted },
];

export const deliveryWindowFilterItems = [
  { label: 'All Windows', value: DeliveryWindowFilter.allWindows },
  { label: 'Night time (+1 day)', value: DeliveryWindowFilter.nightTime },
  { label: 'Day time', value: DeliveryWindowFilter.dayTime },
];

export const allStatesItem = {
  label: 'All States',
  value: 'allStates',
};

export const allCitiesItem = {
  label: 'All Cities',
  value: 'allCities',
};

export const uniqueItems = (
  value: Item,
  index: number,
  self: Item[],
): boolean => index === self.findIndex((t: Item) => t.value === value.value);

export const getFilteredLocations = (
  locations: GetLocation[],
  {
    searchValue,
    routingFilter,
    deliveryWindowFilter,
    stateFilter,
    cityFilter,
    zipFilter,
  }: {
    searchValue: string;
    routingFilter: RoutingFilter;
    deliveryWindowFilter: DeliveryWindowFilter;
    stateFilter: string;
    cityFilter: string;
    zipFilter: string;
  },
): GetLocation[] => {
  let myFilteredLocations = [...locations];
  // search filter
  if (searchValue !== '') {
    const foundIds = locations.filter((location: GetLocation): boolean =>
      location.id.includes(searchValue),
    );
    const foundKeyIds = locations.filter((location: GetLocation): boolean =>
      location.keyId.includes(searchValue),
    );
    const foundBusinessNames = locations.filter(
      (location: GetLocation): boolean =>
        location.businessName.toLowerCase().includes(searchValue.toLowerCase()),
    );
    const foundLocations = [...foundIds, ...foundKeyIds, ...foundBusinessNames];
    // remove duplicates
    myFilteredLocations = foundLocations.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.id === value.id),
    );
  }
  // routing filter
  if (routingFilter !== RoutingFilter.allLocations) {
    if (routingFilter === RoutingFilter.automaticallyRouted) {
      myFilteredLocations = myFilteredLocations.filter(
        (location: GetLocation): boolean =>
          location.includeInAutomatedRouting ?? false,
      );
    } else if (routingFilter === RoutingFilter.manuallyRouted) {
      myFilteredLocations = myFilteredLocations.filter(
        (location: GetLocation): boolean => !location.includeInAutomatedRouting,
      );
    }
  }
  // delivery window filter
  if (deliveryWindowFilter !== DeliveryWindowFilter.allWindows) {
    if (deliveryWindowFilter === DeliveryWindowFilter.nightTime) {
      myFilteredLocations = myFilteredLocations.filter(
        (location: GetLocation): boolean =>
          !isSameDayDeliveryWindow(location.locationDeliveryWindow.startTime),
      );
    } else if (deliveryWindowFilter === DeliveryWindowFilter.dayTime) {
      myFilteredLocations = myFilteredLocations.filter(
        (location: GetLocation): boolean =>
          isSameDayDeliveryWindow(location.locationDeliveryWindow.startTime),
      );
    }
  }
  // state filter
  if (stateFilter !== allStatesItem.value) {
    myFilteredLocations = myFilteredLocations.filter(
      (location: GetLocation): boolean =>
        location.address.state === stateFilter,
    );
  }
  // city filter
  if (cityFilter !== allCitiesItem.value) {
    myFilteredLocations = myFilteredLocations.filter(
      (location: GetLocation): boolean => location.address.city === cityFilter,
    );
  }
  // zip filter
  if (zipFilter !== '') {
    myFilteredLocations = myFilteredLocations.filter(
      (location: GetLocation): boolean =>
        location.address.postalCode.includes(zipFilter),
    );
  }
  return myFilteredLocations;
};
