import { ReactNode } from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { modalSelector } from 'src/state/selectors/modal';
import { hideModal, ModalType } from 'src/state/slices/modal';
import Loader from '../Loader';

import {
  styledConfirmationModal,
  buttonContainer,
  messageStyle,
} from './styles';
import { Flex } from 'theme-ui';

const ConfirmationModal = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element | null => {
  const { type, message, dismissButtonLabel, isLoading } =
    useAppSelector(modalSelector);
  const dispatch = useAppDispatch();

  const handleOnClickCancel = (): void => {
    dispatch(hideModal());
  };

  return (
    <div id="confirmation-modal" sx={styledConfirmationModal}>
      {isLoading ? (
        <Loader
          containerStyle={{ height: '80%', width: '80%' }}
          imgStyle={{ top: '30%', left: '35%' }}
        />
      ) : null}
      <Flex sx={messageStyle}>{message}</Flex>
      <div sx={buttonContainer}>{children}</div>
    </div>
  );
};
export default ConfirmationModal;
