import { Suspense, lazy, useEffect } from 'react';
import { LoadScript } from '@react-google-maps/api';
import { Provider as UrqlProvider } from 'urql';
import { Route, Routes } from 'react-router-dom';
import Navbar, {
  deliveryPath,
  loginPath,
  analyticsPath,
  issuesPath,
  warehouseOperationsPath,
  locationsPath,
  pickingLabelsPath,
  fulfillmentDeltasPath,
  fulfillmentDeltasPrintablePath,
  itemSearchPath,
  packingListPath,
} from './components/Navbar';
import { useAppSelector } from './hooks/store';
import { authSelector } from './state/selectors/auth';
import { client } from './graphql/client';
import ToastNotification from './components/ToastNotification';
import { ThemeUIStyleObject } from 'theme-ui';
import 'react-loading-skeleton/dist/skeleton.css';
import CustomModal from './components/Modal';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { sec } from './services/authService';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import analytics from './services/analytics/index';
import AnalyticsTrackClicks from './services/analytics/TrackClicks';
import UnsupportedBrowsersToast from 'src/components/UnsupportedBrowsersToast';
import Locations from './pages/Locations';
import config from './config';
import PickingLabels from './pages/PickingLabels';
import FulfillmentDeltasPrintable from './pages/FulfillmentDeltasPrintable';
import ItemSearch from './pages/ItemSearch';

const GOOGLE_MAPS_API_KEY = config().google_maps_api_key;
// Use React lazy to code-split our pages
const Login = lazy(() => import('./pages/Login'));
const RouteManagement = lazy(() => import('./pages/Delivery'));
const Analytics = lazy(() => import('./pages/Analytics'));
const Issues = lazy(() => import('./pages/Issues'));
const WarehouseOperations = lazy(() => import('./pages/WarehouseOperations'));
const FulfillmentDeltas = lazy(() => import('./pages/FulfillmentDeltas'));
const PackingList = lazy(() => import('./pages/PackingList'));

const mainStyle: ThemeUIStyleObject = {
  height: '100vh',
};

const ProtectedRoute = ({
  Component,
  ...args
}: {
  Component: React.ComponentType<object>;
}): JSX.Element => {
  if (process.env.CYPRESS_AUTH_MOCKED === 'true')
    return (
      <div>
        <Component />
      </div>
    );
  const MyComponent = withAuthenticationRequired(Component, args);
  return <MyComponent />;
};

const getRouteManagementPage = (): JSX.Element => {
  if (process.env.CYPRESS_AUTH_MOCKED === 'true') return <RouteManagement />;
  return <ProtectedRoute Component={RouteManagement} />;
};

const getAnalyticsPage = (): JSX.Element => {
  if (process.env.CYPRESS_AUTH_MOCKED === 'true') return <Analytics />;
  return <ProtectedRoute Component={Analytics} />;
};

const getIssuesPage = (): JSX.Element => {
  if (process.env.CYPRESS_AUTH_MOCKED === 'true') return <Issues />;
  return <ProtectedRoute Component={Issues} />;
};

const getWarehouseOperationsPage = (): JSX.Element => {
  if (process.env.CYPRESS_AUTH_MOCKED === 'true')
    return <WarehouseOperations />;
  return <ProtectedRoute Component={WarehouseOperations} />;
};

const getLocationsPage = (): JSX.Element => {
  if (process.env.CYPRESS_AUTH_MOCKED === 'true') return <Locations />;
  return <ProtectedRoute Component={Locations} />;
};

const getPickingLabelsPage = (): JSX.Element => {
  if (process.env.CYPRESS_AUTH_MOCKED === 'true') return <PickingLabels />;
  return <ProtectedRoute Component={PickingLabels} />;
};

const getFulfillmentDeltasPage = (): JSX.Element => {
  if (process.env.CYPRESS_AUTH_MOCKED === 'true') return <FulfillmentDeltas />;
  return <ProtectedRoute Component={FulfillmentDeltas} />;
};

const ItemSearchPage = (): JSX.Element => {
  if (process.env.CYPRESS_AUTH_MOCKED === 'true') return <ItemSearch />;
  return <ProtectedRoute Component={ItemSearch} />;
};

const getFulfillmentDeltasPrintablePage = (): JSX.Element => {
  if (process.env.CYPRESS_AUTH_MOCKED === 'true')
    return <FulfillmentDeltasPrintable />;
  return <ProtectedRoute Component={FulfillmentDeltasPrintable} />;
};

const getPackingListPage = (): JSX.Element => {
  if (process.env.CYPRESS_AUTH_MOCKED === 'true') return <PackingList />;
  return <ProtectedRoute Component={PackingList} />;
};

const App = (): JSX.Element => {
  const { email } = useAppSelector(authSelector);
  const ldClient = useLDClient();
  const { getAccessTokenSilently } = useAuth0();
  sec.setAccessTokenSilently(getAccessTokenSilently);

  useEffect(() => {
    if (ldClient && email && !process.env.CYPRESS_AUTH_MOCKED) {
      ldClient.identify({ key: email });
    }

    if (email) {
      // This means data captured will *not* be annonymous.
      // Rather than `indentify` assigning an annonymous id,
      // it will be tied to the user's email.
      // As this is an internal app, I believe this is OK / ideal.
      analytics.identify(email);
    }
  }, [ldClient, email]);

  return (
    <UrqlProvider value={client}>
      <main sx={mainStyle}>
        <Suspense fallback={<div>Loading...</div>}>
          <AnalyticsTrackClicks>
            <Navbar />
            <ToastNotification />
            <CustomModal />
            <UnsupportedBrowsersToast />
            <LoadScript id="googleMap" googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
              <Routes>
                <Route path={loginPath} element={<Login />} />
                <Route path={deliveryPath} element={getRouteManagementPage()} />
                <Route path={analyticsPath} element={getAnalyticsPage()} />
                <Route path={issuesPath} element={getIssuesPage()} />
                <Route
                  path={warehouseOperationsPath}
                  element={getWarehouseOperationsPage()}
                />
                <Route path={locationsPath} element={getLocationsPage()} />
                <Route
                  path={`${pickingLabelsPath}/:warehouseId/:warehouseName/:nightEnd`}
                  element={getPickingLabelsPage()}
                />
                <Route
                  path={fulfillmentDeltasPath}
                  element={getFulfillmentDeltasPage()}
                />
                <Route
                  path={`${fulfillmentDeltasPrintablePath}/:warehouseId/:nightEnd`}
                  element={getFulfillmentDeltasPrintablePage()}
                />
                <Route path={itemSearchPath} element={ItemSearchPage()} />
                <Route path={packingListPath} element={getPackingListPage()} />
                <Route path="/" element={<Login />} />
              </Routes>
            </LoadScript>
          </AnalyticsTrackClicks>
        </Suspense>
      </main>
    </UrqlProvider>
  );
};

export default App;
