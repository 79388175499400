import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import expireReducer from 'redux-persist-expire';
import storage from 'redux-persist/lib/storage';

import AppReducer from './slices/app';
import AuthReducer from './slices/auth';
import MessageReducer from './slices/message';
import DeliveryReducer from './slices/delivery';
import NotificationReducer from './slices/notification';
import ModalReducer from './slices/modal';
import IssuesReducer from './slices/issues';
import OperationsReducer from './slices/operations';
import FulfillmentDeltasReducer from './slices/fulfillmentDeltas';

const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
  transforms: [
    expireReducer('auth', {
      expireSeconds: 86400, // 24 hours
      autoExpire: true,
    }),
  ],
};

const deliveryPersistConfig = {
  key: 'delivery',
  storage,
  whitelist: ['selectedWarehouseId'],
};

const rootReducer = combineReducers({
  app: AppReducer,
  auth: AuthReducer,
  message: MessageReducer,
  delivery: persistReducer(deliveryPersistConfig, DeliveryReducer),
  notification: NotificationReducer,
  modal: ModalReducer,
  issues: IssuesReducer,
  operations: OperationsReducer,
  fulfillmentDeltas: FulfillmentDeltasReducer,
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
