import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type RouteQuantity = {
  routeId: string;
  orderedQuantity: number;
  fulfilledQuantity: number;
};

export type FulfillmentDeltasState = {
  routeQuantities: RouteQuantity[];
};

const initialState: FulfillmentDeltasState = {
  routeQuantities: [],
};

const fulfillmentDeltasSlice = createSlice({
  name: 'fulfillmentDeltas',
  initialState,
  reducers: {
    clearRouteQuantities: (
      state: FulfillmentDeltasState,
    ): FulfillmentDeltasState => {
      return { ...state, routeQuantities: [] };
    },
    updateRouteQuantity: (
      state: FulfillmentDeltasState,
      action: PayloadAction<RouteQuantity>,
    ): FulfillmentDeltasState => {
      const myRouteQuantities = [...state.routeQuantities];
      const foundRouteQuantitiesIndex = myRouteQuantities.findIndex(
        (routeQuantity: RouteQuantity): boolean =>
          routeQuantity.routeId === action.payload.routeId,
      );
      if (foundRouteQuantitiesIndex === -1)
        myRouteQuantities.push(action.payload);
      else myRouteQuantities[foundRouteQuantitiesIndex] = action.payload;
      return { ...state, routeQuantities: myRouteQuantities };
    },
  },
});

const { reducer, actions } = fulfillmentDeltasSlice;
export const { updateRouteQuantity, clearRouteQuantities } = actions;
export default reducer;
