import type { Theme } from 'theme-ui';

// From https://theme-ui.com/guides/typescript/

const makeTheme = <T extends Theme>(t: T): T => t;

export const theme = makeTheme({
  fonts: {
    body: 'GreycliffCF-Regular',
    heading: 'GreycliffCF-Bold',
    label: 'GreycliffCF-Medium',
  },
  fontSizes: [12, 14, 16, 20, 24, 32],
  fontWeights: {
    regular: 500,
    semibold: 600,
    bold: 700,
  },
  // NOTE: Design prefers to use absolute values for line-height rather than percentages.
  lineHeights: ['16px', '20px', '24px', '32px', '40px'],
  space: [
    0, 4, 8, 12, 16, 20, 24, 32, 40, 48, 56, 64, 72, 80, 96, 112, 128, 144,
  ],
  radii: [0, 4, 8, 16, 32, 40, 48, 56, 64],
  sizes: [0, 4, 8, 16, 32, 40, 48, 56, 64],
  shadows: {
    standard: '0px 4px 24px rgba(60, 57, 49, 0.12)',
    bold: '0px 4px 24px rgba(60, 57, 49, 0.25)',
  },
  colors: {
    white: '#FFFFFF',
    black: '#000000',
    text: '#000000',
    backgroundMedium: '#EEEBE6',
    backgroundWeak: '#F4F3EF',
    primaryBackgroundHover: '#E0A100',
    primaryBackground: '#F3BB0B',
    primaryBackgroundActive: '#FBE8A8',
    primaryBackgroundWeak: '#FFF5D6',
    secondaryBackgroundHover: '#2F2A23',
    secondaryBackground: '#3C3931',
    secondaryText: '#615E54',
    secondaryBackgroundActive: '#C0BAAE',
    secondaryBackgroundDisabled: '#E6E2DA',
    warningBackgroundHover: '#AE4B00',
    warningBackground: '#FF760F',
    warningBackgroundActive: '#FFCFAB',
    warningBackgroundWeak: '#FFECDE',
    successBackgroundHover: '#617103',
    successBackground: '#ACC806',
    successBackgroundActive: '#E2ECA8',
    successBackgroundWeak: '#F4F7DF',
    infoBackgroundHover: '#1868C7',
    infoBackground: '#589FF1',
    infoBackgroundActive: '#B2DBFF',
    infoBackgroundWeak: '#E5F4FF',
    errorBackgroundHover: '#8B1407',
    errorBackground: '#F4230B',
    errorBackgroundActive: '#FFD6D1',
    errorBackgroundWeak: '#FFF3F2',
    secondaryDisabled: '#E6E2DA',
    disabledButtonText: '#615E54',
    neutralText: '#050400',
  },
  forms: {
    input: {
      width: '100%',
      bg: 'white',
      color: 'text',
      border: '1px solid #E6E2DA',
      boxSizing: 'border-box',
      borderRadius: '12px',
      paddingLeft: '28px',
      paddingRight: '28px',
      paddingTop: '12px',
      paddingBottom: '12px',
      marginTop: '24px',
      fontFamily: 'body',
      fontSize: '16px',
    },
    label: {
      fontFamily: 'heading',
      fontSize: '28px',
      lineHeight: '36px',
      color: 'black',
    },
    select: {
      borderColor: 'backgroundMedium',
      width: 'auto',
      padding: '10px 40px 10px 16px',
      fontFamily: 'body',
      fontSize: 1,
      borderRadius: 2,
    },
  },
  text: {
    displayLg: {
      fontFamily: 'heading',
      fontSize: '56px',
      lineHeight: '64px',
      color: 'neutralText',
    },
    displayMd: {
      fontFamily: 'heading',
      fontSize: '48px',
      lineHeight: '56px',
      color: 'neutralText',
    },
    displaySm: {
      fontFamily: 'heading',
      fontSize: '40px',
      lineHeight: '48px',
      color: 'neutralText',
    },
    headingLg: {
      fontFamily: 'heading',
      fontSize: '32px',
      lineHeight: '40px',
      color: 'neutralText',
    },
    headingMd: {
      fontFamily: 'heading',
      fontSize: '24px',
      lineHeight: '32px',
      color: 'neutralText',
    },
    headingSm: {
      fontFamily: 'heading',
      fontSize: '20px',
      lineHeight: '24px',
      color: 'neutralText',
    },
    paragraphLgDefault: {
      fontFamily: 'body',
      fontSize: '16px',
      lineHeight: '24px',
      color: 'neutralText',
    },
    paragraphMdDefault: {
      fontFamily: 'body',
      fontSize: '14px',
      lineHeight: '20px',
      color: 'neutralText',
    },
    paragraphSmDefault: {
      fontFamily: 'body',
      fontSize: '12px',
      lineHeight: '16px',
      color: 'neutralText',
    },
    paragraphLgEmphasis: {
      fontFamily: 'heading',
      fontSize: '16px',
      lineHeight: '24px',
      color: 'neutralText',
    },
    paragraphMdEmphasis: {
      fontFamily: 'heading',
      fontSize: '14px',
      lineHeight: '20px',
      color: 'neutralText',
    },
    paragraphSmEmphasis: {
      fontFamily: 'heading',
      fontSize: '12px',
      lineHeight: '16px',
      color: 'neutralText',
    },
    labelLg: {
      fontFamily: 'label',
      fontSize: '16px',
      lineHeight: '20px',
      fontWeight: 700,
      color: 'neutralText',
    },
    labelMd: {
      fontFamily: 'label',
      fontSize: '14px',
      lineHeight: '18px',
      fontWeight: 700,
      color: 'neutralText',
    },
    labelSm: {
      fontFamily: 'label',
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 700,
      color: 'neutralText',
    },
    link: {
      color: 'text',
      fontFamily: 'body',
      fontSize: '16px',
    },
  },
  buttons: {
    ghostIcon: {
      justifyContent: 'center',
      alignItems: 'center',
      border: '0px',
      borderRadius: '0px',
      backgroundColor: 'inherit',
      cursor: 'pointer',
      '&:disabled': {
        color: 'secondaryText',
        bg: 'neutralBackground',
        cursor: 'not-allowed',
      },
    },
  },
});

export type TypedTheme = typeof theme;
