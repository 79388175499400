import { ReactNode, useEffect, useState } from 'react';
import { keyframes } from '@emotion/react';
import { ThemeUICSSObject } from 'theme-ui';

type Dimension = {
  expanded: number;
  collapsed: number;
};
type TransitionProps = {
  height?: Dimension;
  width?: Dimension;
  toggleAnimation?: boolean;
  children: ReactNode;
  style?: ThemeUICSSObject;
  animationSpeed?: number;
};

const calculateCollapsedScale = (dimension: Dimension): number => {
  return dimension.collapsed === 0 && dimension.expanded === 0
    ? 0
    : dimension.collapsed / dimension.expanded;
};

const ease = (v: number, pow = 4): number => {
  return 1 - Math.pow(1 - v, pow);
};

const createKeyframeAnimation = (
  expand: boolean,
  height?: Dimension,
  width?: Dimension,
): string => {
  // Figure out the size of the element when collapsed.
  const x = width ? calculateCollapsedScale(width) : 1;
  const y = height ? calculateCollapsedScale(height) : 1;

  let animation = '';

  for (let step = 0; step <= 100; step++) {
    // Remap the step value to an eased one.
    let easedStep = ease(step / 100);
    const getScale = (v: number): number => {
      return v + (1 - v) * easedStep;
    };
    const inverseGetScale = (v: number): number => {
      return 1 + (v - 1) * easedStep;
    };

    // Calculate the scale of the element.
    const xScale = expand ? getScale(x) : inverseGetScale(x);
    const yScale = expand ? getScale(y) : inverseGetScale(y);
    const widthAnimation = width ? `width: ${width.expanded * xScale}px;` : ``;
    const heightAnimation = height
      ? `height: ${height.expanded * yScale}px;`
      : ``;

    if (step === 100 && expand) {
      animation += `${step}% {
          width: auto;
          height: auto;
        }`;
    } else {
      animation += `${step}% {
          ${widthAnimation}
          ${heightAnimation}
        }`;
    }
  }

  return animation;
};

const Transition = ({
  height,
  width,
  toggleAnimation,
  animationSpeed = 0.5,
  style = {},
  children,
}: TransitionProps): JSX.Element => {
  const [animationCss, setAnimationCss] = useState<ThemeUICSSObject>({});

  useEffect(() => {
    if (toggleAnimation !== undefined) {
      const animation = createKeyframeAnimation(toggleAnimation, height, width);

      const animationKeyFrame = keyframes`${animation}`;

      const containerCss: ThemeUICSSObject = {
        animation: `${animationKeyFrame} ${animationSpeed}s forwards`,
        transformOrigin: `top left `,
        overflow: toggleAnimation ? 'visible' : 'hidden',
      };
      setAnimationCss(containerCss);
    }
  }, [toggleAnimation, animationSpeed, height, width]);
  if (toggleAnimation === undefined) return <div sx={style}>{children}</div>;

  return <div sx={{ ...style, ...animationCss }}>{children}</div>;
};

export default Transition;
