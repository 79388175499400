import { Order } from 'src/graphql/types/delivery';
import { getHHMMSSTimeFromDateString, isDateTimeAfter } from 'src/utils/date';

export const isOutsideDeliveryWindow = ({
  order,
  timeZone,
  viewingNightEnd,
}: {
  order: Order;
  timeZone?: string;
  viewingNightEnd?: string;
}): boolean => {
  if (!viewingNightEnd) return false;

  if (!timeZone || !viewingNightEnd) {
    // a drop-off task isn't outside the delivery window just because "it's 5 o'clock somewhere"
    console.warn('a delivery is on-time if no timezone is specified');
    return false;
  }

  if (!order.deliveredAt) {
    // "a drop off task isn't outside the delivery window if its undelivered" ;)
    return false;
  }
  let deliveredAtTime = getHHMMSSTimeFromDateString(
    order.deliveredAt,
    timeZone,
  );
  if (deliveredAtTime === undefined) return false;

  const { endTime } = order.location.locationDeliveryWindow;

  return isDateTimeAfter({
    deliveredAt: order.deliveredAt,
    endTime,
    viewingDate: viewingNightEnd,
    timeZone,
  });
};
