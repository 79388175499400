import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { DELIVERY_STOPS_REORDER } from 'src/graphql/requests/mutations';
import { client } from 'src/graphql/client';
import { Delivery, PickUp, Route } from 'src/graphql/types/delivery';
import {
  GDeliveryStopsReorderInput,
  GDeliveryStopsReorderPayload,
} from 'src/graphql/types/generated';
import { NotificationType, showNotification } from '../notification';
import { DeliveryState } from './state';
import {
  CANNOT_CHANGE_DATA,
  INVALID_NIGHT_END,
  isUserErrorInArray,
  updateSequenceNumbersRedelivery,
} from 'src/utils';

export const deliveryStopsReorder = createAsyncThunk(
  'delivery/deliveryStopsReorder',
  async (
    {
      routeId,
      stopId,
      destinationSequenceNumber,
      redelivery,
    }: GDeliveryStopsReorderInput,
    thunkAPI,
  ): Promise<GDeliveryStopsReorderPayload | null> => {
    const { data, error } = await client
      .mutation(DELIVERY_STOPS_REORDER, {
        input: { routeId, stopId, destinationSequenceNumber, redelivery },
      })
      .toPromise();
    if (
      error ||
      !data.deliveryStopsReorder ||
      data.deliveryStopsReorder.userErrors.length > 0
    ) {
      const isInvalidNightEnd = isUserErrorInArray(
        INVALID_NIGHT_END,
        data.deliveryStopsReorder.userErrors,
      );
      const message = isInvalidNightEnd ? CANNOT_CHANGE_DATA : undefined;
      thunkAPI.dispatch(
        showNotification({
          type: NotificationType.Error,
          message,
        }),
      );
      return null;
    }
    return data.deliveryStopsReorder;
  },
);

export const deliveryStopsReorderFulfilled = (
  state: DeliveryState,
  action: PayloadAction<GDeliveryStopsReorderPayload | null>,
): void => {
  const currentRoutesState = state.routes ? [...state.routes] : [];
  if (action?.payload?.route) {
    const { route: payloadRoute } = action.payload;
    state.routes = currentRoutesState.map((currentRoute) => {
      if (currentRoute.id === payloadRoute?.id) {
        currentRoute.stops = payloadRoute.stops as Array<Delivery | PickUp>;
        currentRoute.redeliveryStops = updateSequenceNumbersRedelivery(
          payloadRoute as Route,
        ).redeliveryStops;
      }
      return currentRoute;
    });
  }
  state.deliveryStopsReorderLoading = false;
};

export const deliveryStopsReorderPending = (state: DeliveryState): void => {
  state.deliveryStopsReorderLoading = true;
};
