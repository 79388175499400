import {
  Button,
  ButtonIcon,
  IconEdit3,
  Spacer,
  TextInput,
} from '@odekoteam/doppio';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import {
  DeliveredQuantityEditor,
  closeSecondaryCards,
  setEditDeliveredQuantityCardVisible,
} from 'src/state/slices/delivery';
import { SecondaryMapCard } from './MapCards/SecondaryMapCard';
import { deliveredQuantityEditorSelector } from 'src/state/selectors/delivery';
import { useEffect, useState } from 'react';
import { useMutation } from 'urql';
import { UPDATE_ORDER_QUANTITY_DELIVERED } from 'src/graphql/requests/mutations';
import Loader from './Loader';
import { Flex } from 'theme-ui';
import { GUpdateOrderQuantityDeliveredMutation } from 'src/graphql/types/generated';
import {
  NotificationType,
  showNotification,
} from 'src/state/slices/notification';

export const EditDeliveredQuantityButton = ({
  orderId,
  orderLineId,
  quantityDelivered,
  itemName,
  orderUnitQuantity,
}: DeliveredQuantityEditor): JSX.Element => {
  const dispatch = useAppDispatch();
  return (
    <ButtonIcon
      id={`editDeliveredQuantityButton-${orderLineId}`}
      variant="ghost"
      onPress={(): void =>
        dispatch(
          setEditDeliveredQuantityCardVisible({
            orderId,
            orderLineId,
            quantityDelivered,
            itemName,
            orderUnitQuantity,
          }),
        )
      }
    >
      <IconEdit3 />
    </ButtonIcon>
  );
};

const EditDeliveredQuantityCard = (): JSX.Element | null => {
  const {
    orderId,
    orderLineId,
    quantityDelivered,
    itemName,
    orderUnitQuantity,
  } = useAppSelector(deliveredQuantityEditorSelector);
  const dispatch = useAppDispatch();
  const [newQuantityDelivered, setNewQuantityDelivered] = useState('');
  const [updateOrderQuantityDeliveredResult, updateOrderQuantityDelivered] =
    useMutation<GUpdateOrderQuantityDeliveredMutation>(
      UPDATE_ORDER_QUANTITY_DELIVERED,
    );
  const isMoreThanOrderedQty =
    Number(newQuantityDelivered) > (orderUnitQuantity ?? 0);

  const isSaveButtonDisabled = (): boolean => {
    if (Number(newQuantityDelivered) === quantityDelivered) return true;
    if (newQuantityDelivered === '') return true;
    if (isMoreThanOrderedQty) return true;
    return false;
  };

  const saveClicked = (): void => {
    updateOrderQuantityDelivered({
      input: {
        orderId,
        orderLines: [
          {
            id: orderLineId,
            deliveredQuantity: Number(newQuantityDelivered),
          },
        ],
        redelivery: true, // this prevents the mutation from updating the deliveredAt timestamp
      },
    });
  };

  useEffect(() => {
    setNewQuantityDelivered(`${quantityDelivered}`);
  }, [quantityDelivered]);

  useEffect(() => {
    if (!updateOrderQuantityDeliveredResult) return;
    const { fetching, data, error } = updateOrderQuantityDeliveredResult;
    if (fetching) return;
    // success
    if ((data?.updateOrderQuantityDelivered?.orderLines?.length ?? 0) > 0) {
      dispatch(closeSecondaryCards());
      dispatch(
        showNotification({
          type: NotificationType.Success,
          message: `The delivered quantity has been updated!`,
        }),
      );
      return;
    }
    // failure if we have an error or data that didn't meet the above criteria
    if (error || data) {
      dispatch(
        showNotification({
          type: NotificationType.Error,
        }),
      );
      return;
    }
  }, [
    updateOrderQuantityDeliveredResult.data,
    updateOrderQuantityDeliveredResult.error,
  ]);

  return (
    <SecondaryMapCard id="route-details-editor">
      <div sx={{ variant: 'text.headingSm', marginBottom: '16px' }}>
        Edit Quantity Delivered
      </div>
      {updateOrderQuantityDeliveredResult.fetching && (
        <Loader
          containerStyle={{ height: '80%', width: '90%' }}
          imgStyle={{ top: '35%', left: '40%' }}
        />
      )}
      <div
        data-test-id="itemName"
        sx={{ variant: 'text.labelMd', marginBottom: '4px' }}
      >
        {itemName}
      </div>
      <TextInput
        onChange={(e): void => {
          const value = e.target.value;
          if (Number(value) >= 0 || value === '')
            setNewQuantityDelivered(value);
        }}
        value={newQuantityDelivered}
        helperText={
          isMoreThanOrderedQty
            ? 'You cannot enter more than the customer has ordered'
            : ''
        }
      />
      <Flex sx={{ marginTop: '16px' }}>
        <Button
          id="edit-route-details-save-button"
          variant="secondary"
          isDisabled={isSaveButtonDisabled()}
          onPress={saveClicked}
        >
          Save Changes
        </Button>
        <Spacer width="$1" />
        <Button
          id="edit-route-details-cancel-button"
          variant="ghost"
          onPress={(): void => dispatch(closeSecondaryCards())}
        >
          Cancel
        </Button>
      </Flex>
    </SecondaryMapCard>
  );
};

export default EditDeliveredQuantityCard;
