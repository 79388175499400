import { createSelector } from 'reselect';
import { RootState } from '../store';
import { AppState } from '../slices/app';

export const appSelector: (state: RootState) => AppState = (state: RootState) =>
  state.app;

export const isNavCollapsedSelector = createSelector(appSelector, (app) => {
  return app.isNavCollapsed;
});
