import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { Spacer } from '@odekoteam/doppio';
import { Flex } from 'theme-ui';
import { useState } from 'react';
import { modalSelector } from 'src/state/selectors/modal';
import { messageStyle, styledConfirmationModal } from './styles';
import { GMarkItemsAsBulkPickedMutation } from 'src/graphql/types/generated';
import { useMutation } from 'urql';
import { hideModal } from 'src/state/slices/modal';
import { LoaderSpinner } from '../Loader';
import { MARK_ITEMS_AS_BULK_PICKED } from 'src/graphql/requests/markItemsAsBulkPicked';
import { selectedWarehouseIdSelector } from 'src/state/selectors/delivery';
import LargeTextInput from '../LargeTextInput';
import {
  NotificationType,
  showNotification,
} from 'src/state/slices/notification';

const UpdateBulkItemsModal = (): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const selectedWarehouseId = useAppSelector(selectedWarehouseIdSelector);
  const [textInputValue, setTextInputValue] = useState('');
  const {
    variables: { refetch },
  } = useAppSelector(modalSelector);
  const [markItemsAsBulkPickedResult, markItemsAsBulkPicked] =
    useMutation<GMarkItemsAsBulkPickedMutation>(MARK_ITEMS_AS_BULK_PICKED);

  const handleOnClickCancel = (): void => {
    dispatch(hideModal());
  };

  const handleOnClickSubmit = (): void => {
    const ids = textInputValue.split(',').map((itemId) => itemId.trim());
    markItemsAsBulkPicked({
      input: {
        warehouseId: selectedWarehouseId,
        itemIds: ids,
        isBulkedPicked: true,
      },
    }).then(({ data, error }) => {
      if (error || !data?.markItemsAsBulkPicked?.success) {
        dispatch(
          showNotification({
            type: NotificationType.Error,
            message: data?.markItemsAsBulkPicked?.userErrors[0].message,
          }),
        );
      } else {
        dispatch(
          showNotification({
            type: NotificationType.Success,
            message: 'Items have been updated successfully!',
          }),
        );
        refetch({ requestPolicy: 'cache-and-network' });
        dispatch(hideModal());
      }
    });
  };

  const handleOnChange = (newValue: string): void => {
    setTextInputValue(newValue);
  };

  return (
    <div
      id="mark-bulk-items-modal"
      sx={{
        ...styledConfirmationModal,
        maxWidth: '800px',
        minWidth: ['90%', '80%', '700px'],
      }}
    >
      <Flex sx={messageStyle}>Add Bulk Items</Flex>
      <Spacer height="$4" />
      <Flex sx={{ justifyContent: 'center', marginTop: '24px' }}>
        {markItemsAsBulkPickedResult.fetching && (
          <LoaderSpinner
            imgStyle={{
              marginLeft: '-200px',
              bottom: '32px',
              height: '20px',
              top: 'auto',
              left: 'auto',
            }}
          />
        )}
        <LargeTextInput
          id="mark-bulk-items-text-input"
          placeholder="Add comma separated list of item ids (i.e. 874d9784-0780-4f7d-bc62-299b33ba9dcc, 874d...)"
          value={textInputValue}
          onChange={handleOnChange}
          onSubmit={handleOnClickSubmit}
          onCancel={handleOnClickCancel}
          submitDisabled={textInputValue === ''}
        />
      </Flex>
    </div>
  );
};
export default UpdateBulkItemsModal;
