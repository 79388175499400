import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { CREATE_WAREHOUSE_DELIVERY_ROUTE } from 'src/graphql/requests/mutations';
import { client } from 'src/graphql/client';
import {
  GDeliveryRouteCreateInput,
  GDeliveryRouteCreatePayload,
} from 'src/graphql/types/generated';
import { NotificationType, showNotification } from '../notification';
import { hideModal, setIsModalLoading } from '../modal';
import { DeliveryState, RouteStatusState } from './state';
import { Route } from 'src/graphql/types/delivery';
import {
  CANNOT_CHANGE_DATA,
  INVALID_NIGHT_END,
  isUserErrorInArray,
  naturalSort,
} from 'src/utils';

export const createWarehouseDeliveryRoute = createAsyncThunk(
  'delivery/createWarehouseDeliveryRoute',
  async (
    { warehouseId, nightEnd, redelivery }: GDeliveryRouteCreateInput,
    thunkAPI,
  ): Promise<GDeliveryRouteCreatePayload | null> => {
    thunkAPI.dispatch(setIsModalLoading(true));
    const { data, error } = await client
      .mutation(CREATE_WAREHOUSE_DELIVERY_ROUTE, {
        input: { warehouseId, nightEnd, name: '', redelivery },
      })
      .toPromise();
    if (
      error ||
      !data.createWarehouseDeliveryRoute ||
      data.createWarehouseDeliveryRoute.userErrors.length > 0
    ) {
      const isInvalidNightEnd = isUserErrorInArray(
        INVALID_NIGHT_END,
        data.createWarehouseDeliveryRoute.userErrors,
      );
      const message = isInvalidNightEnd ? CANNOT_CHANGE_DATA : undefined;
      thunkAPI.dispatch(hideModal());
      thunkAPI.dispatch(
        showNotification({
          type: NotificationType.Error,
          message,
        }),
      );
      thunkAPI.dispatch(setIsModalLoading(false));
      return null;
    }
    thunkAPI.dispatch(hideModal());
    thunkAPI.dispatch(setIsModalLoading(false));
    thunkAPI.dispatch(
      showNotification({
        type: NotificationType.Success,
        message: 'Route created successfully!',
      }),
    );
    return data.createWarehouseDeliveryRoute;
  },
);

export const createWarehouseDeliveryRouteFulfillment = (
  state: DeliveryState,
  action: PayloadAction<GDeliveryRouteCreatePayload | null>,
): void => {
  const currentRoutesState = state.routes ? [...state.routes] : [];
  const newRoute = action.payload?.route;

  if (newRoute) {
    state.routes = [...currentRoutesState, newRoute as Route].sort(
      (aImpl: Route, bImpl: Route) => naturalSort(aImpl.name, bImpl.name),
    );
    const defaultRouteStatus: RouteStatusState = {
      routeId: newRoute.id,
      totalStops: 0,
      totalStopsVisited: 0,
      hasSecondaryStatuses: false,
      totalUndeliveredStops: 0,
      totalPartiallyDeliveredStops: 0,
      totalNotPickedUpStops: 0,
      totalPartiallyPickedUpStops: 0,
      totalGoldMembers: 0,
      totalSilverMembers: 0,
      totalDoorDashStops: 0,
      totalRefrigerationRequired: 0,
      totalFreezerRequired: 0,
      totalBienCuitStops: 0,
      totalPastryStops: 0,
    };
    state.deliveryStatuses = {
      ...state.deliveryStatuses,
      routeStatuses: [
        ...state.deliveryStatuses.routeStatuses,
        defaultRouteStatus,
      ],
    };
  }

  state.createWarehouseDeliveryRoute.isSaving = false;
};
