export type LabelData = {
  routeNumber: string;
  stopNumber: number;
  stopName: string;
  itemName: string;
  quantityType: string;
  itemsPerLot: number;
  orderQuantityIndex: string;
};

export const listToMatrix = (
  list: LabelData[],
  elementsPerSubArray: number,
): LabelData[][] => {
  let matrix: LabelData[][] = [];
  let i = 0;
  let k = -1;
  for (i = 0, k = -1; i < list.length; i++) {
    if (i % elementsPerSubArray === 0) {
      k++;
      matrix[k] = [];
    }
    matrix[k].push(list[i]);
  }
  return matrix;
};
