import { ThemeUICSSObject } from 'theme-ui';
import { dash, solidHorizontal, solidVertical } from './styles';

export enum DividerTypes {
  dash = 'dash',
  solidHorizontal = 'solidHorizontal',
  solidVertical = 'solidVertical',
}

export type DividerProps = {
  type: DividerTypes;
  style?: ThemeUICSSObject;
  color?: string;
};

const Divider = ({ type, style, color }: DividerProps): JSX.Element | null => {
  let dividerStyle = {};
  switch (type) {
    case DividerTypes.dash:
      dividerStyle = { ...dash };
      break;
    case DividerTypes.solidHorizontal:
      dividerStyle = { ...solidHorizontal };
      break;
    case DividerTypes.solidVertical:
      dividerStyle = { ...solidVertical };
      break;
  }
  const colorStyle = color ? { borderColor: color } : {};
  return <div sx={{ ...dividerStyle, ...style, ...colorStyle }} />;
};

export default Divider;
