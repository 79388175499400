import { TrackTargetPath, TrackEvent } from './types';

/**
 * Generates default tracking data for a given click event.
 * @param {Event} event - The event to generate default tracking data for.
 * @returns {TrackEvent} - An array containing the name of the event and the custom tracking data.
 * The custom tracking data contains a path, location, and component properties.
 */
function generateDefaults(event: Event): TrackEvent {
  /* Defaults Event Properties */

  // Save path, closest proxy to "location"
  const pathHTMLElements = event
    .composedPath()
    .filter(
      (e: EventTarget): Boolean => e instanceof HTMLElement,
    ) as HTMLElement[];
  const path = pathHTMLElements.map((e: HTMLElement): TrackTargetPath => {
    return {
      tagName: e.nodeName,
      className: e.className,
      dataset: JSON.stringify(e.dataset),
    };
  });

  // Derive name from target
  const target = event.target as HTMLElement;
  const label = target?.innerText ? `"${target?.innerText}" ` : '';
  const name = `${label}Button Clicked`;

  // e.g., `css-1c0cz63-RouteListCard` -> `RouteListCard`
  const component = path[0].className.split('-').pop();

  return [name, { path, component }];
}

/**
 * Finds custom tracking data for a given event.
 * @param {Event} event - The event to find custom tracking data for.
 * @returns {TrackEvent} - An array containing the name of the event and the custom tracking data.
 * The custom tracking data contains a path, location, and component properties.
 */
function findCustomTrackingData(event: Event): TrackEvent {
  const trackingData: { [key: string]: string } = {};
  /* Check `data-atts` for Custom Event Properties */

  // 1. Traverse thru the path
  // 2. Reverse the order, as the event is bubbling up (so things will be reversed)
  // 3. Look for all non-empty `data-atts`
  // 4. Reduce to only events with `data-atts`
  const data = event
    .composedPath()
    .reverse()
    .filter(
      (e: EventTarget): Boolean =>
        Object.keys((e instanceof HTMLElement && e.dataset) || {}).length !== 0,
    ) as HTMLElement[];

  // 5. Find first instance of `data-atts` that has `data-track-*` and discard rest
  const dataAtts: DOMStringMap[] = data.map(
    (e: HTMLElement): DOMStringMap => e.dataset,
  );

  let foundTrackingData = false;

  for (const [_, obj] of dataAtts.entries()) {
    // We only care about the first instance of tracking data
    if (foundTrackingData) break;

    for (const [key, value] of Object.entries(obj)) {
      if (key.includes('track') && value) {
        // remove `track` and lowercase first letter
        const newKey = key[5].toLowerCase() + key.slice(6);
        trackingData[newKey] = value;
        foundTrackingData = true;
      }
    }
  }

  const name = trackingData.hasOwnProperty('name') ? trackingData['name'] : '';

  // remove name since we're returning this as the first param
  delete trackingData['name'];

  return [name, trackingData];
}

export { generateDefaults, findCustomTrackingData };
