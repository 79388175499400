import { useEffect, ReactNode } from 'react';
import { TrackEvent } from './types';
import { generateDefaults, findCustomTrackingData } from './utils';
import analytics from './index';

const AnalyticsTrackClicks = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const trackClick = (event: Event): void => {
    const [defaultName, defaultTrackingData]: TrackEvent =
      generateDefaults(event);
    const [customName, customTrackingData]: TrackEvent =
      findCustomTrackingData(event);
    const eventName = customName !== '' ? customName : defaultName;

    analytics.track(eventName, {
      ...defaultTrackingData,
      ...customTrackingData,
    });
  };

  useEffect(() => {
    // React events don't bubble back up, so using a JS event listener with the last param set to `true`
    // to give us a better chance of grabbing events
    window.addEventListener('click', trackClick, true);

    return () => {
      window.removeEventListener('click', trackClick, true);
    };
  }, []);

  return <div>{children}</div>;
};

export default AnalyticsTrackClicks;
