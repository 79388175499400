import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type AuthState = {
  isUserAuthenticated: boolean;
  isUserLoggedIn: boolean;
  email: string | undefined;
};

const initialState: AuthState = {
  isUserAuthenticated: process.env.CYPRESS_AUTH_MOCKED === 'true',
  isUserLoggedIn: false,
  email: undefined,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthState: (
      state: AuthState,
      action: PayloadAction<{
        isUserAuthenticated: boolean;
        isUserLoggedIn: boolean;
        email: string | undefined;
      }>,
    ) => {
      return { ...state, ...action.payload };
    },
  },
});

const { reducer, actions } = authSlice;
export const { setAuthState } = actions;
export default reducer;
