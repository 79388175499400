import { selectedDateLabel } from './styles';
import { useAppSelector } from 'src/hooks/store';
import { nightEndSelector } from 'src/state/selectors/delivery';
import Skeleton from 'react-loading-skeleton';
import { adjustForTimezone } from 'src/utils/date';

const DateDisplay = (): JSX.Element => {
  const nightEnd = useAppSelector(nightEndSelector);
  if (!nightEnd.viewing)
    return (
      <Skeleton sx={{ ...selectedDateLabel, width: '160px', height: '30px' }} />
    );
  const date = adjustForTimezone(new Date(nightEnd.viewing));
  const month = date.toLocaleString('default', { month: 'long' });
  const day = date.getDate();
  const year = date.getFullYear();
  return (
    <span
      id="dateDisplay"
      sx={selectedDateLabel}
    >{`${month} ${day}, ${year}`}</span>
  );
};

export default DateDisplay;
