import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import {
  nightEndSelector,
  selectedWarehouseIdSelector,
} from 'src/state/selectors/delivery';
import { createWarehouseDeliveryRoute } from 'src/state/slices/delivery';
import { Button, Spacer, Checkbox, Tooltip } from '@odekoteam/doppio';
import CancelButton from './CancelButton';
import { Flex } from 'theme-ui';
import { useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

const CreateRouteButton = (): JSX.Element | null => {
  const nightEnd = useAppSelector(nightEndSelector);
  const dispatch = useAppDispatch();
  const [isRedelivery, setIsRedelivery] = useState(false);
  const selectedWarehouseId = useAppSelector(selectedWarehouseIdSelector);

  const handleAction = (): void => {
    if (selectedWarehouseId) {
      dispatch(
        createWarehouseDeliveryRoute({
          warehouseId: selectedWarehouseId,
          nightEnd: nightEnd.viewing,
          redelivery: isRedelivery,
        }),
      );
    }
  };

  return (
    <div>
      <Tooltip labelText="" placement="bottom">
        <div data-test-id="recover-route-checkbox">
          <Checkbox
            onChange={(isChecked): void => setIsRedelivery(isChecked)}
            size="default"
            isChecked={isRedelivery}
          >
            This is a recovery route
          </Checkbox>
        </div>
      </Tooltip>
      <Flex sx={{ justifyContent: 'center', marginTop: '24px' }}>
        <Button
          id="modal-create-route-action-button"
          variant="secondary"
          onPress={handleAction}
        >
          Yes
        </Button>
        <Spacer width="$1" />
        <CancelButton />
      </Flex>
    </div>
  );
};
export default CreateRouteButton;
