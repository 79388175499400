import { useAppDispatch } from 'src/hooks/store';
import { Attachment } from 'src/graphql/types/delivery';
import { showModal, ModalType } from '../../state/slices/modal';
import {
  uploadedImagesTitleWrapper,
  uploadedImagesTitle,
  imageCountContainer,
  imageCountStyle,
  noImagesLabelStyle,
  imageGalleryWrapper,
  thumbnailStyle,
  imageListStyle,
  loaderStyles,
} from './styles';
import Skeleton from 'react-loading-skeleton';

const ImageCount = ({ count }: { count: number }): JSX.Element | null => {
  return count > 0 ? (
    <div id="uploaded-images-count" sx={imageCountContainer}>
      <span sx={imageCountStyle}>{count}</span>
    </div>
  ) : null;
};

const Thumbnail = ({
  url,
  attachments,
  index,
}: {
  url: string;
  attachments?: Array<Attachment>;
  index: number;
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const handleOnClickThumbnail = (): void => {
    dispatch(
      showModal({
        type: ModalType.Fullscreen,
        variables: {
          attachments,
          index,
        },
      }),
    );
  };

  return (
    <div id="image-thumbnail" onClick={handleOnClickThumbnail}>
      <img src={url} alt="image" sx={thumbnailStyle} />
    </div>
  );
};

const ImageLoader = (): JSX.Element => (
  <div id="image-list-loader" sx={imageListStyle}>
    <Skeleton sx={loaderStyles} />
  </div>
);

const ImageList = ({
  attachments,
  flexDirection,
}: {
  attachments?: Array<Attachment>;
  flexDirection: 'row' | 'column';
}): JSX.Element => (
  <div>
    {!attachments || attachments.length === 0 ? (
      <span sx={noImagesLabelStyle} id="no-images-label">
        No images uploaded
      </span>
    ) : (
      <div id="image-list" sx={{ ...imageListStyle, flexDirection }}>
        {attachments.map((item, index) => {
          const { url, id } = item;
          return (
            <Thumbnail
              url={url}
              key={id}
              attachments={attachments}
              index={index}
            />
          );
        })}
      </div>
    )}
  </div>
);
const ImageGallery = ({
  attachments,
  isLoading,
  flexDirection = 'row',
}: {
  attachments?: Array<Attachment>;
  isLoading?: boolean;
  flexDirection?: 'row' | 'column';
}): JSX.Element => {
  const deliveryAttachments = attachments
    ? attachments.filter(
        (attachment) => attachment.attachmentCategory === 'delivery',
      )
    : [];

  return (
    <div sx={imageGalleryWrapper}>
      <div id="uploaded-images-title" sx={uploadedImagesTitleWrapper}>
        <span sx={uploadedImagesTitle}>Uploaded Images</span>
        {deliveryAttachments && deliveryAttachments.length > 0 && (
          <ImageCount count={deliveryAttachments.length} />
        )}
      </div>
      {isLoading ? (
        <ImageLoader />
      ) : (
        <ImageList
          attachments={deliveryAttachments}
          flexDirection={flexDirection}
        />
      )}
    </div>
  );
};

export default ImageGallery;
