const HighlightedText = ({
  text,
  highlight = '',
}: {
  text: string;
  highlight?: string;
}): JSX.Element => {
  if (highlight === '') return <span>{text}</span>;
  // Split on highlight term and include term into parts, ignore case
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
  return (
    <span>
      {parts.map((part, i) => (
        <span
          key={i}
          style={
            part.toLowerCase() === highlight.toLowerCase()
              ? { backgroundColor: 'yellow' }
              : {}
          }
        >
          {part}
        </span>
      ))}
    </span>
  );
};

export default HighlightedText;
