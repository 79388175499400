import { ThemeUIStyleObject, ThemeUICSSObject } from 'theme-ui';

export const pageContainer: ThemeUIStyleObject = {
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  paddingX: ['10px', '20px'],
};

export const headerWrapper: ThemeUICSSObject = {
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
};
