import { gql } from 'urql';
import { PRODUCT } from '../client';
import { ROUTE_FRAGMENT } from './fragments';

export const GET_ROUTES = gql`
  query ${PRODUCT}GetRoutes($input: RoutesInput!) {
    ${ROUTE_FRAGMENT}
    routes(input: $input) {
      ...RouteFragment
    }
  }
`;
