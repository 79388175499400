import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { LabelData } from './utils';

// Create styles
const styles = StyleSheet.create({
  label: {
    width: '192px',
    height: '192px',
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #F4F3EF',
    overflow: 'hidden',
    justifyContent: 'space-between',
  },
  header: {
    fontSize: '24px',
  },
  paragraph: {
    fontSize: '16px',
  },
  paragraphBold: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  stopName: {
    fontSize: '16px',
    height: '34px',
    overflow: 'hidden',
  },
  itemName: {
    fontSize: '16px',
    fontWeight: 'bold',
    maxHeight: '72px',
    overflow: 'hidden',
  },
});

const Label = ({
  labelData,
  warehouseName,
  nightEnd,
  id,
}: {
  labelData: LabelData;
  warehouseName: string;
  nightEnd: string;
  id: string;
}): JSX.Element => {
  const {
    stopNumber,
    stopName,
    routeNumber,
    itemName,
    quantityType,
    itemsPerLot,
    orderQuantityIndex,
  } = labelData;
  const caseQuantity = quantityType === 'case' ? ` of ${itemsPerLot}` : '';
  return (
    <View id={id} style={styles.label}>
      <View id={`${id}-header`} style={styles.header}>
        <Text>
          S: {stopNumber} R: {routeNumber}
        </Text>
      </View>
      <View id={`${id}-stopName`} style={styles.stopName}>
        <Text>{stopName}</Text>
      </View>
      <View id={`${id}-itemName`} style={styles.itemName}>
        <Text>{itemName}</Text>
      </View>
      <View style={styles.paragraphBold}>
        <Text id={`${id}-warehouseName`}>({warehouseName}) </Text>
        <Text id={`${id}-quantityType`}>
          ({quantityType}
          {caseQuantity})
        </Text>
      </View>
      <View id={`${id}-quantity`} style={styles.paragraph}>
        <Text>{orderQuantityIndex}</Text>
      </View>
      <View id={`${id}-deliveryDate`} style={styles.paragraph}>
        <Text>{nightEnd}</Text>
      </View>
    </View>
  );
};

export default Label;
