import Skeleton from 'react-loading-skeleton';
import { Flex } from 'theme-ui';
import { Button, Spacer, TextArea } from '@odekoteam/doppio';
import { charCounterStyle, textBoxStyle } from './styles';

const MAX_LINES = 4;

export type LargeTextInputProps = {
  placeholder: string;
  onChange: (newValue: string) => void;
  onSubmit: () => void;
  onCancel: () => void;
  isLoading?: boolean;
  value?: string;
  maxLength?: number;
  id?: string;
  submitDisabled?: boolean;
};

const EditableLargeTextInput = ({
  placeholder,
  value,
  maxLength,
  onChange,
  onSubmit,
  onCancel,
  id,
  submitDisabled,
  isLoading,
}: LargeTextInputProps): JSX.Element => {
  const showGrayBackground =
    submitDisabled && value?.length && value.length > 0;
  const showCharCounter =
    maxLength !== undefined &&
    value?.length !== undefined &&
    !showGrayBackground;
  return (
    <Flex id={`${id}Container`} sx={{ flexDirection: 'column', width: '100%' }}>
      {isLoading ? (
        <div
          sx={{
            ...textBoxStyle,
            borderStyle: 'solid',
          }}
        >
          <Skeleton count={2} />
        </div>
      ) : (
        <div
          id={`${id}Background`}
          sx={{
            position: 'relative',
            borderRadius: 2,
            backgroundColor: showGrayBackground ? 'backgroundWeak' : 'white',
          }}
        >
          <span
            sx={{
              ...charCounterStyle,
              visibility: showCharCounter ? 'visible' : 'hidden',
            }}
          >
            {value?.length}/{maxLength}
          </span>
          <TextArea
            placeholder={placeholder}
            onChangeText={(value): void => {
              const splitByNewLine = value.split(/\r?\n/);
              // only allow 4 lines
              const truncatedString =
                splitByNewLine.length > MAX_LINES
                  ? splitByNewLine.splice(0, MAX_LINES).join('\n')
                  : value;
              onChange(truncatedString);
            }}
            value={value ?? ''}
            maxLength={maxLength}
            numberOfLines={MAX_LINES}
            id={id}
            editable={!isLoading}
            // @ts-ignore
            wrap="hard"
          />
        </div>
      )}
      <Spacer height="$4" />
      <Flex>
        <Button
          id={`${id}Submit`}
          variant="secondary"
          isDisabled={isLoading || submitDisabled}
          onPress={(): void => onSubmit()}
        >
          Submit
        </Button>
        <Spacer width="$1" />
        <Button
          id={`${id}Cancel`}
          variant="ghost"
          isDisabled={isLoading}
          onPress={(): void => onCancel()}
        >
          Cancel
        </Button>
      </Flex>
    </Flex>
  );
};

export default EditableLargeTextInput;
