import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum NotificationType {
  Success = 'Success',
  Error = 'Error',
}

export type Link = {
  url: string;
  label: string;
};

export type Route = {
  id: string;
  name: string;
};

export type NotificationState = {
  type: NotificationType;
  isShowing?: boolean;
  message?: string;
  link?: Link;
  errorCode?: string;
  route?: Route;
};

const initialState: NotificationState = {
  isShowing: undefined,
  type: NotificationType.Success,
  message: undefined,
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    showNotification: (
      state: NotificationState,
      action: PayloadAction<{
        type: NotificationType;
        message?: string;
        link?: Link;
        errorCode?: string;
        route?: Route;
      }>,
    ) => {
      return {
        isShowing: true,
        type: action.payload.type,
        message: action.payload.message,
        link: action.payload.link,
        errorCode: action.payload.errorCode,
        route: action.payload.route,
      };
    },
    hideNotification: (state: NotificationState) => {
      return {
        ...state,
        isShowing: false,
        message: undefined,
        link: undefined,
        errorCode: undefined,
        route: undefined,
      };
    },
  },
  extraReducers: (builder) => {},
});

const { reducer, actions } = notificationSlice;
export const { showNotification, hideNotification } = actions;
export default reducer;
