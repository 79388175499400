import { IconDeliveryLine, IconUserDefault, Tooltip } from '@odekoteam/doppio';
import BoxIcon from 'assets/box.svg';
import ExternalDelivery from 'assets/externalDelivery.svg';
import TimeClockChecked from 'assets/timeClockChecked.svg';
import { Text } from 'theme-ui';
import { tag, tagIcon as tagIconStyle, text as textStyle } from './styles';
import IconWeight from 'assets/iconWeight.svg';
import IconVolume from 'assets/iconVolume.svg';

export enum TagIcons {
  box = 'box',
  deliveryLine = 'deliveryLine',
  iconUserDefault = 'iconUserDefault',
  timeClockChecked = 'timeClockChecked',
  externalPartner = 'externalPartner',
  dollar = 'dollar',
  weight = 'weight',
  volume = 'volume',
}

const TagIcon = ({ icon }: { icon?: TagIcons }): JSX.Element | null => {
  let tagIcon = null;
  if (icon === TagIcons.box) {
    tagIcon = (
      <div sx={{ paddingTop: '4px', marginRight: '11px' }}>
        <BoxIcon />
      </div>
    );
  }
  if (icon === TagIcons.deliveryLine) {
    tagIcon = (
      <div sx={{ paddingTop: '4px', marginRight: '11px' }}>
        <IconDeliveryLine height={24} width={24} />
      </div>
    );
  }
  if (icon === TagIcons.iconUserDefault) {
    tagIcon = (
      <div sx={{ paddingTop: '4px', marginRight: '4px' }}>
        <IconUserDefault height={24} width={24} />
      </div>
    );
  }
  if (icon === TagIcons.timeClockChecked) {
    tagIcon = (
      <div sx={{ paddingTop: '7px', marginRight: '4px' }}>
        <TimeClockChecked />
      </div>
    );
  }
  if (icon === TagIcons.externalPartner) {
    tagIcon = (
      <div sx={{ paddingTop: '7px', marginRight: '4px' }}>
        <ExternalDelivery />
      </div>
    );
  }
  if (icon === TagIcons.dollar) {
    tagIcon = <div sx={{ marginRight: '4px' }}>$</div>;
  }
  if (icon === TagIcons.weight) {
    tagIcon = (
      <div sx={{ paddingTop: '4px', marginRight: '4px' }}>
        <IconWeight />
      </div>
    );
  }
  if (icon === TagIcons.volume) {
    tagIcon = (
      <div sx={{ paddingTop: '4px', marginRight: '4px' }}>
        <IconVolume />
      </div>
    );
  }
  return tagIcon;
};

const Tag = ({
  text,
  style,
  icon,
  variant,
  color,
  tooltip = '',
}: {
  text: string;
  style?: React.CSSProperties;
  icon?: TagIcons;
  variant?: string;
  color?: string;
  tooltip?: string;
}): JSX.Element | null => {
  const id =
    text
      .replaceAll(' ', '-')
      .replaceAll(':', '-')
      .replaceAll('.', '-')
      .toLowerCase() + '-tag';

  let tagStyle =
    icon === undefined
      ? { ...tag, ...style }
      : { ...tag, ...tagIconStyle, ...style };

  return (
    <Tooltip labelText={tooltip} placement="bottom">
      <div sx={tagStyle} id={id}>
        <TagIcon icon={icon} />
        <Text variant={variant} color={color} sx={textStyle}>
          {text}
        </Text>
      </div>
    </Tooltip>
  );
};

export default Tag;
