import { useAppDispatch } from 'src/hooks/store';
import { collapseNav } from 'src/state/slices/app';
import { helpLinkLabel, navLinkContainer } from './styles';
import { IconSupport } from '@odekoteam/doppio';

const LABEL = 'Help Center';
const helpCenterPath =
  'https://www.notion.so/odeko/User-Guide-Delivery-Management-Application-8f66da1c0870411da052c3c90f20caa9';

const HelpCenter = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const handleClick = (): void => {
    dispatch(collapseNav());
  };

  return (
    <a
      href={helpCenterPath}
      id="navLinkHelpCenter"
      sx={navLinkContainer}
      onClick={handleClick}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div>
        <IconSupport height={24} width={24} />
      </div>
      <div sx={helpLinkLabel}>{LABEL}</div>
    </a>
  );
};

export default HelpCenter;
