import { OrderLine, RefrigerationState } from 'src/graphql/types/delivery';
import { Flex, ThemeUICSSObject } from 'theme-ui';
import Tag from './Tag';
import {
  deliveryPrimaryStatusesSelector,
  deliveryStatusesSelector,
} from '../state/selectors/delivery';
import { useAppSelector } from '../hooks/store';
import { EditDeliveredQuantityButton } from './EditDeliveredQuantity';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { GDeliveryStatusDmp } from 'src/graphql/types/generated';
import RefrigerationRequiredIcon from './RefrigerationRequiredIcon';
import FreezerRequiredIcon from './FreezerRequiredIcon';
import HighlightedText from './HighlightedText';

const itemDetailsTitleContainer: ThemeUICSSObject = {
  display: 'flex',
  fontFamily: 'body',
  variant: 'text.labelMd',
  color: 'secondaryText',
};

const itemDetailsFooterContainer: ThemeUICSSObject = {
  display: 'flex',
  paddingTop: '8px',
};

const itemDetailsItemName: ThemeUICSSObject = {
  paddingTop: '4px',
  maxWidth: '280px',
};

const itemDetailsItemType: ThemeUICSSObject = {
  fontFamily: 'body',
  variant: 'text.labelSm',
  color: 'secondaryText',
};

const itemDetailsQuantityDelivered: ThemeUICSSObject = {
  fontFamily: 'body',
  variant: 'text.labelSm',
  color: 'secondaryText',
  textAlign: 'right',
  marginLeft: 'auto',
};

const itemDetailsQuantityFulfilled: ThemeUICSSObject = {
  fontFamily: 'body',
  variant: 'text.labelSm',
  color: 'secondaryText',
  textAlign: 'right',
  marginLeft: 'auto',
  display: 'inline-block',
};

export type TagStyleModel = {
  itemLevelTraking: boolean;
  isStopVisited: boolean;
  isUndelivered: boolean;
  isPartiallyDelivered: boolean;
  deliveredQuantity: number;
  orderUnitQuantity: number;
};

const getTagStyle = (model: TagStyleModel): React.CSSProperties => {
  return (model.itemLevelTraking && model.isUndelivered) ||
    (model.itemLevelTraking &&
      (model.isStopVisited || model.isPartiallyDelivered) &&
      model.deliveredQuantity < model.orderUnitQuantity)
    ? { marginRight: '0px', backgroundColor: 'errorBackgroundWeak' }
    : { marginRight: '0px' };
};

export const OrderLineInfo = ({
  itemLevelTraking = false,
  isStopVisited = false,
  orderLine,
  isFulfilled = true,
  stopId,
  orderId,
  editable = true,
  searchValue,
}: {
  itemLevelTraking?: boolean;
  isStopVisited?: boolean;
  orderLine: OrderLine;
  isFulfilled?: boolean;
  stopId: string;
  orderId: string;
  editable?: boolean;
  searchValue?: string;
}): JSX.Element | null => {
  // Uses primary statuses only
  const { deliveryServiceSeparatePrimaryStatusState } = useFlags();
  const deliveryAllStatuses = useAppSelector(deliveryStatusesSelector);
  const deliveryPrimaryStatuses = useAppSelector(
    deliveryPrimaryStatusesSelector,
  );
  const deliveryStatuses = deliveryServiceSeparatePrimaryStatusState
    ? deliveryPrimaryStatuses
    : deliveryAllStatuses;
  const myStopStatus = deliveryStatuses.stopStatuses.find(
    (stopStatus) => stopStatus.stopId === stopId,
  );

  const itemName = orderLine.sku?.name;
  const deliveredQuantity = orderLine.deliveredQuantity ?? 0;
  const orderUnitQuantity = orderLine.orderUnitQuantity;
  const fulfillmentQuantity = orderLine.fulfillmentQuantity ?? 0;

  // Bail on whole order line as it's not part of the order/delivery since it was zeroed out
  if (!orderUnitQuantity) return null;

  const quantityType =
    orderLine.quantityType.charAt(0).toUpperCase() +
    orderLine.quantityType.slice(1);

  const showQuantityDelivered =
    !itemLevelTraking || (itemLevelTraking && isStopVisited);
  const quantityDelivered = itemLevelTraking
    ? deliveredQuantity + ' delivered'
    : 'Qty:' + orderUnitQuantity;
  const quantity =
    quantityType === 'Case'
      ? quantityType + ' of ' + orderLine.itemsPerLot
      : 'Each';

  const fulfillmentQuantityStyle =
    itemLevelTraking && !isFulfilled
      ? { color: 'secondaryBackgroundActive' }
      : {};
  const tagStyle = getTagStyle({
    itemLevelTraking,
    isStopVisited,
    isUndelivered: myStopStatus?.stopStatus === GDeliveryStatusDmp.Undelivered,
    isPartiallyDelivered:
      myStopStatus?.stopStatus === GDeliveryStatusDmp.PartiallyDelivered,
    deliveredQuantity,
    orderUnitQuantity,
  });
  return (
    <div id={`item-${orderLine.id}`}>
      <div sx={itemDetailsTitleContainer}>
        <div id="item-name" sx={itemDetailsItemName}>
          <HighlightedText text={itemName} highlight={searchValue} />
        </div>
        {showQuantityDelivered && (
          <Flex sx={itemDetailsQuantityDelivered}>
            <Tag text={quantityDelivered} style={tagStyle} />
            {itemLevelTraking && editable && (
              <EditDeliveredQuantityButton
                orderId={orderId}
                orderLineId={orderLine.id}
                quantityDelivered={deliveredQuantity}
                itemName={itemName}
                orderUnitQuantity={orderUnitQuantity}
              />
            )}
          </Flex>
        )}
      </div>
      <div sx={itemDetailsFooterContainer}>
        <Flex>
          <div id="item-type" sx={itemDetailsItemType}>
            {quantity}
          </div>
          {orderLine.sku.refrigerationState ===
            RefrigerationState.refrigerated && (
            <RefrigerationRequiredIcon style={{ marginLeft: '8px' }} />
          )}
          {orderLine.sku.refrigerationState === RefrigerationState.frozen && (
            <FreezerRequiredIcon style={{ marginLeft: '8px' }} />
          )}
        </Flex>
        {itemLevelTraking && (
          <div id="fulfillment-quantity" sx={itemDetailsQuantityFulfilled}>
            <span id="fulfillment-quantity-text" sx={fulfillmentQuantityStyle}>
              {fulfillmentQuantity} fulfilled
            </span>{' '}
            · {orderUnitQuantity} ordered
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderLineInfo;
