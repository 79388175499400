import { gql } from 'urql';
import { PRODUCT } from '../client';

export const UPDATE_LOCATIONS_INCLUDE_IN_AUTOMATED_ROUTING = gql`
  mutation ${PRODUCT}UpdateLocationsIncludeInAutomatedRouting($input: LocationsIncludeInAutomatedRoutingUpdateInput!) {
    updateLocationsIncludeInAutomatedRouting(input: $input) {
      locations {
        id
        includeInAutomatedRouting
      }
    }
  }
`;
