import { ThemeUICSSObject } from 'theme-ui';

export const container: ThemeUICSSObject = {
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
};

export const backdrop: ThemeUICSSObject = {
  position: 'fixed',
  width: '100%',
  height: '100%',
  background: 'black',
  opacity: '0.25',
  zIndex: '100',
};

export const styledConfirmationModal: ThemeUICSSObject = {
  position: 'absolute',
  maxWidth: '350px',
  top: '35%',
  background: 'white',
  borderRadius: '16px',
  zIndex: '101',
  padding: '24px 22px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
};

export const styledFullscreenModal: ThemeUICSSObject = {
  position: 'absolute',
  display: 'flex',
  background: 'white',
  borderRadius: '12px',
  zIndex: '101',
  margin: '20px',
  height: '90%',
  width: '95%',
  justifyContent: 'center',
  alignItems: 'center',
};

export const exitButtonWrapper: ThemeUICSSObject = {
  position: 'absolute',
  right: '38px',
  top: '38px',
  cursor: 'pointer',
  height: '30px',
};

export const messageStyle: ThemeUICSSObject = {
  fontFamily: 'body',
  fontSize: 2,
  color: 'black',
  textAlign: 'center',
};

export const buttonContainer: ThemeUICSSObject = {
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  marginTop: '24px',
};

export const image: ThemeUICSSObject = {
  display: 'flex',
  width: '100%',
  height: '100%',
  objectFit: 'contain',
};
