import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { Button, Spacer, Switch } from '@odekoteam/doppio';
import CancelButton from './CancelButton';
import { Flex } from 'theme-ui';
import { useEffect, useState } from 'react';
import { modalSelector } from 'src/state/selectors/modal';
import { GetLocation } from 'src/graphql/types/delivery';
import Expandable from '../StopDetailsCard/Expandable';
import { messageStyle, styledConfirmationModal } from './styles';
import { GUpdateLocationsIncludeInAutomatedRoutingMutation } from 'src/graphql/types/generated';
import { useMutation } from 'urql';
import { UPDATE_LOCATIONS_INCLUDE_IN_AUTOMATED_ROUTING } from 'src/graphql/requests/updateLocationsIncludeInAutomatedRouting';
import { hideModal } from 'src/state/slices/modal';
import { LoaderSpinner } from '../Loader';
import { updateRoutingLocations } from 'src/state/slices/locations';

const AutomatedRoutingConfirmation = (): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const {
    variables: { locations },
  } = useAppSelector(modalSelector);
  const [includeInAutomatedRouting, setIncludeInAutomatedRouting] =
    useState<boolean>(true);
  const getLabel = (): string => (includeInAutomatedRouting ? 'ON' : 'OFF');
  const [
    updateLocationsIncludeInAutomatedRoutingResult,
    updateLocationsIncludeInAutomatedRouting,
  ] = useMutation<GUpdateLocationsIncludeInAutomatedRoutingMutation>(
    UPDATE_LOCATIONS_INCLUDE_IN_AUTOMATED_ROUTING,
  );

  const handleAction = (): void => {
    const locationIds = locations.map(
      (location: GetLocation): string => location.id,
    );
    updateLocationsIncludeInAutomatedRouting({
      input: {
        locationIds,
        includeInAutomatedRouting,
      },
    });
  };

  useEffect(() => {
    if (updateLocationsIncludeInAutomatedRoutingResult.data) {
      const routingLocations = locations.map((location: GetLocation) => ({
        locationId: location.id,
        value: includeInAutomatedRouting,
      }));
      dispatch(updateRoutingLocations([routingLocations]));
      dispatch(hideModal());
    }
  }, [updateLocationsIncludeInAutomatedRoutingResult]);

  return (
    <div
      id="automated-routing-modal"
      sx={{ ...styledConfirmationModal, maxWidth: '800px' }}
    >
      <Flex sx={messageStyle}>Update locations</Flex>
      <Spacer height="$4" />
      <Flex sx={{ alignItems: 'center', marginBottom: '10px' }}>
        <span sx={{ variant: 'text.paragraphMdDefault' }}>
          Update automated routing to:
        </span>
        <div sx={{ marginX: '8px' }}>
          <Switch
            id="automated-routing-switch-bulk"
            accessibilityLabel="automated routing toggle"
            onCheckedChange={(): void =>
              setIncludeInAutomatedRouting(!includeInAutomatedRouting)
            }
            size="default"
            isChecked={includeInAutomatedRouting}
          />
        </div>
        <span sx={{ variant: 'text.paragraphLgDefault' }}>{getLabel()}</span>
      </Flex>
      <div sx={{ variant: 'text.paragraphMdDefault' }}>
        You will be updating {locations.length} locations. Would you like to
        turn automated routing{' '}
        <b sx={{ variant: 'text.labelMd' }}>{getLabel()}</b> for these{' '}
        {locations.length} locations?
      </div>
      <Spacer height="$3" />
      <Expandable
        title="Locations to update"
        id="locations"
        expanded={false}
        showCompletedIndicator={false}
      >
        <div sx={{ maxHeight: '30vh', overflow: 'auto' }}>
          {locations.map((location: GetLocation) => (
            <div
              key={location.id}
              sx={{
                variant: 'text.paragraphMdDefault',
                marginBottom: '4px',
                marginLeft: '4px',
              }}
            >
              {location.businessName}
            </div>
          ))}
        </div>
      </Expandable>
      <Flex sx={{ justifyContent: 'center', marginTop: '24px' }}>
        {updateLocationsIncludeInAutomatedRoutingResult.fetching && (
          <LoaderSpinner
            imgStyle={{
              marginLeft: '-200px',
              bottom: '32px',
              height: '20px',
              top: 'auto',
              left: 'auto',
            }}
          />
        )}
        <Button
          id="modal-update-automated-routing-action-button"
          variant="secondary"
          onPress={handleAction}
          disabled={updateLocationsIncludeInAutomatedRoutingResult.fetching}
        >
          Update
        </Button>
        <Spacer width="$1" />
        <CancelButton />
      </Flex>
    </div>
  );
};
export default AutomatedRoutingConfirmation;
