import {
  GetStopStatusMyRedeliveryStop,
  GetStopStatusMyStop,
  GetStopStatusOrder,
} from 'src/graphql/types/delivery';
import { StopStatusState } from 'src/state/slices/delivery';

export const foundIssues = (
  stop: GetStopStatusMyStop | GetStopStatusMyRedeliveryStop,
): boolean => {
  const orderIssue = (stop.orders as GetStopStatusOrder[])?.find(
    (order: GetStopStatusOrder) => (order.issues?.length ?? 0) > 0,
  );
  return orderIssue !== undefined || !!stop.issues?.length;
};

export const foundNotes = (
  stop: GetStopStatusMyStop | GetStopStatusMyRedeliveryStop,
): boolean => {
  const issueWithDriverNote = stop.issues?.find((issue) => {
    return !!issue.note;
  });
  const orderIssuesWithNote = (stop.orders as GetStopStatusOrder[])?.find(
    (order: GetStopStatusOrder) =>
      order.issues?.find((orderIssue) => !!orderIssue.note),
  );
  return orderIssuesWithNote !== undefined || issueWithDriverNote !== undefined;
};

export const foundDeliveryNote = (
  stop: GetStopStatusMyStop | GetStopStatusMyRedeliveryStop,
): boolean => {
  const deliveryNote =
    stop.__typename === 'Delivery' ? stop.deliveryNote : null;
  return !!deliveryNote;
};

export const doesStopHaveInfo = (stopStatuses: StopStatusState[]): boolean => {
  return stopStatuses.some((myStopStatus) => {
    if (!myStopStatus) return false;
    return Object.entries(myStopStatus.stopSecondaryStatus).some(
      ([key, value]) => {
        // we don't want to factor refrigeration state into this status
        if (key === 'isRefrigerationRequired' || key === 'isFreezerRequired')
          return false;
        return value;
      },
    );
  });
};
