import { Button } from '@odekoteam/doppio';
import { useState } from 'react';
import { GGetDeliveryPhotosQuery } from 'src/graphql/types/generated';
import { useQuery } from 'urql';
import { GET_DELIVERY_PHOTOS } from 'src/graphql/requests/getDeliveryPhotos';
import ImageGallery from './StopDetailsCard/ImageGallery';
import { GetDeliveryPhotoStop } from 'src/graphql/types/delivery';

const LoadPhotos = ({ stopId }: { stopId: string }): JSX.Element | null => {
  const [fetchPhotos, setFetchPhotos] = useState(false);
  const [{ fetching, data, error }] = useQuery<GGetDeliveryPhotosQuery>({
    query: GET_DELIVERY_PHOTOS,
    variables: { input: { id: stopId } },
    requestPolicy: 'cache-and-network',
    pause: !fetchPhotos,
  });
  if (!fetchPhotos || error)
    return (
      <Button variant="tertiary" onPress={(): void => setFetchPhotos(true)}>
        Load Photos
      </Button>
    );
  return (
    <ImageGallery
      attachments={(data?.stop as GetDeliveryPhotoStop)?.attachments}
      flexDirection="column"
      isLoading={fetching}
    />
  );
};

export default LoadPhotos;
