import { gql } from 'urql';
import { PRODUCT } from '../client';
import { ADDRESS_FRAGMENT, ISSUE_FRAGMENT, ORDER_LINE } from './fragments';

export const GET_WAREHOUSES = gql`
  query ${PRODUCT}GetWarehouses {
    warehouses {
      id
      name
      currentNightEnd
      timeZone
    }
  }
`;

export const GET_STOP_ADDRESS = gql`
${ADDRESS_FRAGMENT}
  query ${PRODUCT}GetStopAddress($input: StopInput!) {
    stop(input: $input) {
      ... on Delivery {
        location {
          address {
            ...AddressFragment
          }
        }
      }
      ... on PickUp {
        vendor {
          address {
            ...AddressFragment
          }
        }
      }
    }
  }
`;

export const GET_REDELIVERY_STOP_ADDRESS = gql`
${ADDRESS_FRAGMENT}
  query ${PRODUCT}GetRedeliveryStopAddress($input: RedeliveryStopInput!) {
    redeliveryStop(input: $input) {
      location {
        address {
          ...AddressFragment
        }
      }
    }
  }
`;

export const GET_STOP_DELIVERY_DETAILS = gql`
  query ${PRODUCT}GetStopDeliveryDetails($input: StopInput!) {
    stop(input: $input) {
      ... on Delivery {
        reminderMessage
        attachments {
          id
          url
          attachmentCategory
        }
      }
    }
  }
`;

export const GET_ROUTING_IMPORT_PLAN = gql`
  query ${PRODUCT}GetRoutingImportPlan($input: RoutingImportPlanInput!) {
    routingImportPlan(input: $input) {
      id
      status
      truckCount
    }
  }
`;

export const GET_ROUTING_IMPORT_PLAN_CUT_OFF = gql`
  query ${PRODUCT}GetRoutingImportPlanCutOff($routePlanInput: RoutingImportPlanInput!, $cutOffInput: IsPastWarehouseCutOffTimeInput!) {
    routingImportPlan(input: $routePlanInput) {
      id
      status
      truckCount
    }
    isPastWarehouseCutOffTime (input: $cutOffInput)
  }
`;

export const GET_USERS = gql`
  query ${PRODUCT}GetUsers($role: String!) {
    users(role: $role) {
      id
      name
    }
  }
`;

export const GET_ROUTE_ISSUES = gql`
  ${ISSUE_FRAGMENT}
  ${ORDER_LINE}
  query ${PRODUCT}GetRouteIssues($input: RouteInput!) {
    route(input: $input) {
      driverUser {
        id
        name
      }
      stops {
        ... on Delivery {
          id
          deliveryStatusDmp
          issues {
            ...IssueFragment
          }
          location {
            id
          }
          orders {
            id
            deliveredAt
            partiallyDelivered
            deliveryStatus
            itemLevelTracking
            erpRecordUrl
            location {
              locationDeliveryWindow {
                startTime
                endTime
              }
            }
            issues {
              ...IssueFragment
            }
            orderLines {
              ...OrderLineFragment
            }
            vendor {
              name
              isWarehouse
            }
          }
          redeliveryStop {
            id
            deliveryStatusDmp
          }
        }
        ... on PickUp {
          id
          deliveryStatusDmp
          issues {
            ...IssueFragment
          }
          orders {
            id
            collectedAt
            deliveryStatus
            erpRecordUrl
            location {
              locationDeliveryWindow {
                startTime
                endTime
              }
            }
            vendor {
              name
              isWarehouse
            }
            issues {
              ...IssueFragment
            }
          }
        }
      }
    }
  }
`;

export const GET_WAREHOUSE_ORDER_CUTOFF_TIME = gql`
  query ${PRODUCT}GetWarehouseOrderCutOffTime($input: StopInput!) {
  stop(input: $input) {
    ... on Delivery {
      orders {
        vendor {
          isWarehouse
        }
        dueAt
      }
    }
  }
}
`;

export const GET_PRIMARY_STOP_STATUSES = gql`
  query ${PRODUCT}GetPrimaryStopStatuses($input: RoutesInput!) {
    routes(input: $input) {
      __typename
      id
      stops {
        __typename
        ... on Delivery {
          __typename
          deliveryStatusDmp
          id
        }
        ... on PickUp {
          __typename
          deliveryStatusDmp
          id
        }
      }
      redeliveryStops {
        id
        deliveryStatusDmp
      }
    }
  }
`;

export const GET_PICKING_LABEL_DATA = gql`
  query ${PRODUCT}GetPickingLabelData($input: RoutesInput!) {
    routes(input: $input) {
      id
      name
      isLobby
      stops {
        ... on Delivery {
          id
          sequenceNumber
          location {
            id
            businessName
          }
          orders {
            id
            vendor {
              isWarehouse
            }
            orderLines {
              id
              orderUnitQuantity
              quantityType
              itemsPerLot
              bulkPickedItem
              sku {
                id
                refrigerationState
                item {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_IS_ROUTES_SYNC_ENABLED = gql`
  query ${PRODUCT}GetIsRoutesSyncEnabled($isRoutesSyncEnabledInput: IsRoutesSyncEnabledInput!) {
    isRoutesSyncEnabled(input: $isRoutesSyncEnabledInput)
  }
`;
