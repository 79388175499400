import { ThemeUICSSObject } from 'theme-ui';

export const dash: ThemeUICSSObject = {
  borderTop: '1px dashed',
  borderColor: 'backgroundMedium',
};

export const solidHorizontal: ThemeUICSSObject = {
  borderTop: '1px solid',
  borderColor: 'backgroundMedium',
};

export const solidVertical: ThemeUICSSObject = {
  borderLeft: '1px solid',
  borderColor: 'backgroundMedium',
};
