import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IssuesState, SlackIssue, SlackIssues, initialState } from './state';
import {
  deleteRedeliveryStop,
  deleteRedeliveryStopFulfillment,
  deleteRedeliveryStopPending,
} from './deleteRedeliveryStop';
import {
  createRedeliveryStops,
  createRedeliveryStopsFulfillment,
} from './createRedeliveryStops';
import { naturalSort } from 'src/utils';
import { GDeliveryStatusDmp } from 'src/graphql/types/generated';

export const issuesSlice = createSlice({
  name: 'issues',
  initialState,
  reducers: {
    setSelectAllFilter: (
      state: IssuesState,
      action: PayloadAction<boolean>,
    ) => {
      return {
        ...state,
        filters: {
          displayInTransitStops: action.payload,
          displayDeliveredStops: action.payload,
          displayUndeliveredStops: action.payload,
          displayPartiallyDeliveredStops: action.payload,
        },
      };
    },
    setDisplayInTransitStops: (
      state: IssuesState,
      action: PayloadAction<boolean>,
    ) => {
      return {
        ...state,
        filters: { ...state.filters, displayInTransitStops: action.payload },
      };
    },
    setDisplayDeliveredStops: (
      state: IssuesState,
      action: PayloadAction<boolean>,
    ) => {
      return {
        ...state,
        filters: { ...state.filters, displayDeliveredStops: action.payload },
      };
    },
    setDisplayUndeliveredStops: (
      state: IssuesState,
      action: PayloadAction<boolean>,
    ) => {
      return {
        ...state,
        filters: { ...state.filters, displayUndeliveredStops: action.payload },
      };
    },
    setDisplayPartiallyDeliveredStops: (
      state: IssuesState,
      action: PayloadAction<boolean>,
    ) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          displayPartiallyDeliveredStops: action.payload,
        },
      };
    },
    addDisplayedStops: (
      state: IssuesState,
      action: PayloadAction<
        {
          id: string;
          isChecked: boolean;
          stopStatus: GDeliveryStatusDmp;
          redeliveryStopId: string | undefined;
          redeliveryStopStatus: string | undefined;
        }[]
      >,
    ) => {
      // Ensure we are not adding duplicated stops
      const displayedStopsIds = [...state.displayedStops].map(
        (displayedStop) => displayedStop.id,
      );
      const newDisplayedStops = action.payload
        .filter(
          (newDisplayedStop) =>
            !displayedStopsIds.includes(newDisplayedStop.id),
        )
        .map((myStop) => ({ ...myStop, loading: false, stale: false }));

      return {
        ...state,
        displayedStops: [...state.displayedStops, ...newDisplayedStops],
      };
    },
    removeDisplayedStop: (
      state: IssuesState,
      action: PayloadAction<string>,
    ) => {
      const displayedStops = [...state.displayedStops].filter(
        (stop) => stop.id !== action.payload,
      );

      return {
        ...state,
        displayedStops: displayedStops,
      };
    },
    updateStopIsChecked: (
      state: IssuesState,
      action: PayloadAction<{
        id: string;
        isChecked: boolean;
      }>,
    ) => {
      const displayedStops = [...state.displayedStops];
      const stopIndex = displayedStops.findIndex(
        (displayedStop) => displayedStop.id === action.payload.id,
      );
      if (displayedStops[stopIndex]) {
        displayedStops[stopIndex] = {
          ...displayedStops[stopIndex],
          isChecked: action.payload.isChecked,
        };
      }

      return {
        ...state,
        displayedStops: displayedStops,
      };
    },
    updateAllStopsIsChecked: (
      state: IssuesState,
      action: PayloadAction<boolean>,
    ) => {
      const displayedStops = [...state.displayedStops];
      displayedStops.forEach((stop, index) => {
        if (
          stop.redeliveryStopId === undefined &&
          stop.stopStatus !== GDeliveryStatusDmp.Delivered
        ) {
          displayedStops[index] = {
            ...displayedStops[index],
            isChecked: action.payload,
          };
        }
      });

      return {
        ...state,
        displayedStops: displayedStops,
      };
    },
    upsertSlackIssues: (
      state: IssuesState,
      action: PayloadAction<SlackIssues>,
    ) => {
      // access issues
      const myAccessIssues = [...state.slackIssues.accessIssues];
      const newAccessIssues = [...action.payload.accessIssues];
      newAccessIssues.forEach((newAccessIssue) => {
        const foundIssueIndex = myAccessIssues.findIndex(
          (myAccessIssue: SlackIssue): boolean =>
            myAccessIssue.stopId === newAccessIssue.stopId,
        );
        if (foundIssueIndex === -1) myAccessIssues.push(newAccessIssue);
        else myAccessIssues[foundIssueIndex] = newAccessIssue;
      });
      // damaged issues
      const myDamagedIssues = [...state.slackIssues.damagedIssues];
      const newDamagedIssues = [...action.payload.damagedIssues];
      newDamagedIssues.forEach((newDamagedIssue) => {
        const foundIssueIndex = myDamagedIssues.findIndex(
          (myDamagedIssue: SlackIssue): boolean =>
            myDamagedIssue.stopId === newDamagedIssue.stopId,
        );
        if (foundIssueIndex === -1) myDamagedIssues.push(newDamagedIssue);
        else myDamagedIssues[foundIssueIndex] = newDamagedIssue;
      });
      // other issues
      const myOtherIssues = [...state.slackIssues.otherIssues];
      const newOtherIssues = [...action.payload.otherIssues];
      newOtherIssues.forEach((newOtherIssue) => {
        const foundIssueIndex = myOtherIssues.findIndex(
          (myOtherIssue: SlackIssue): boolean =>
            myOtherIssue.stopId === newOtherIssue.stopId,
        );
        if (foundIssueIndex === -1) myOtherIssues.push(newOtherIssue);
        else myOtherIssues[foundIssueIndex] = newOtherIssue;
      });
      return {
        ...state,
        slackIssues: {
          accessIssues: myAccessIssues.sort((issueA, issueB): number =>
            naturalSort(issueA.issueMsg, issueB.issueMsg),
          ),
          damagedIssues: myDamagedIssues.sort((issueA, issueB): number =>
            naturalSort(issueA.issueMsg, issueB.issueMsg),
          ),
          otherIssues: myOtherIssues.sort((issueA, issueB): number =>
            naturalSort(issueA.issueMsg, issueB.issueMsg),
          ),
        },
      };
    },
  },
  extraReducers: (builder) => {
    // ------------ deleteRedeliveryStop ------------
    builder.addCase(
      deleteRedeliveryStop.fulfilled,
      deleteRedeliveryStopFulfillment,
    );
    builder.addCase(deleteRedeliveryStop.pending, deleteRedeliveryStopPending);
    // ------------ createRedeliveryStops ------------
    builder.addCase(
      createRedeliveryStops.fulfilled,
      createRedeliveryStopsFulfillment,
    );
  },
});
