import { Switch, Tooltip } from '@odekoteam/doppio';
import { useEffect, useState } from 'react';
import { UPDATE_LOCATIONS_INCLUDE_IN_AUTOMATED_ROUTING } from 'src/graphql/requests/updateLocationsIncludeInAutomatedRouting';
import { GUpdateLocationsIncludeInAutomatedRoutingMutation } from 'src/graphql/types/generated';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { routingLocationsSelector } from 'src/state/selectors/locations';
import { updateRoutingLocations } from 'src/state/slices/locations';
import { Flex } from 'theme-ui';
import { useMutation } from 'urql';

const AutomatedRoutingSwitch = ({
  locationId,
  value,
}: {
  locationId: string;
  value?: boolean;
}): JSX.Element => {
  const isUndefined = value === undefined || value === null;
  const routingLocations = useAppSelector(routingLocationsSelector);
  const dispatch = useAppDispatch();
  const locationInState = routingLocations.find(
    (location) => location.locationId === locationId,
  );
  const [isChecked, setIsChecked] = useState(
    locationInState ? locationInState.value : value,
  );
  const [isDisabled, setIsDisabled] = useState(false);
  const [
    updateLocationsIncludeInAutomatedRoutingResult,
    updateLocationsIncludeInAutomatedRouting,
  ] = useMutation<GUpdateLocationsIncludeInAutomatedRoutingMutation>(
    UPDATE_LOCATIONS_INCLUDE_IN_AUTOMATED_ROUTING,
  );

  const handleSwitch = (newValue: boolean): void => {
    if (!isDisabled) {
      updateLocationsIncludeInAutomatedRouting({
        input: {
          locationIds: [locationId],
          includeInAutomatedRouting: newValue,
        },
      });
      // optimistic load
      setIsChecked(newValue);
      dispatch(
        updateRoutingLocations([
          {
            locationId,
            value: newValue,
          },
        ]),
      );
      // debounce
      setIsDisabled(true);
    }
  };

  useEffect(() => {
    const includeInAutomatedRouting =
      updateLocationsIncludeInAutomatedRoutingResult?.data
        ?.updateLocationsIncludeInAutomatedRouting?.locations[0]
        .includeInAutomatedRouting;
    if (includeInAutomatedRouting !== undefined) {
      // if the mutation fails - update the switch
      setIsChecked(includeInAutomatedRouting);
      dispatch(
        updateRoutingLocations([
          {
            locationId,
            value: includeInAutomatedRouting,
          },
        ]),
      );
      setIsDisabled(false);
    }
  }, [
    updateLocationsIncludeInAutomatedRoutingResult?.data
      ?.updateLocationsIncludeInAutomatedRouting?.locations,
  ]);

  const getLabel = (): string => {
    if (isUndefined) return 'Disabled';
    else if (isChecked) return 'On';
    else return 'Off';
  };
  return (
    <Tooltip
      labelText={isUndefined ? 'Location is not set up for delivery' : ''}
      placement="bottom"
    >
      <Flex>
        <Switch
          id={`automated-routing-switch-${locationId}`}
          accessibilityLabel="automated routing toggle"
          onCheckedChange={handleSwitch}
          size="default"
          isChecked={isChecked}
          isDisabled={isUndefined}
        />
        <span sx={{ marginLeft: '12px', variant: 'text.paragraphLgDefault' }}>
          {getLabel()}
        </span>
      </Flex>
    </Tooltip>
  );
};

export default AutomatedRoutingSwitch;
