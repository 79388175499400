/* eslint-disable max-nested-callbacks */
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Divider, { DividerTypes } from 'src/components/Divider';
import { deliveryPath } from 'src/components/Navbar';
import OrderLineInfo from 'src/components/OrderLineInfo';
import OrderLinks from 'src/components/OrderLinks';
import { LoadingRow, TableCell, TableRow } from 'src/components/Table';
import { GET_FULFILLMENT_DELTAS } from 'src/graphql/requests/getFulfillmentDeltas';
import {
  Delivery,
  GetFulfillmentDelivery,
  GetFulfillmentOrder,
  OrderLine,
  PickUp,
  Route,
} from 'src/graphql/types/delivery';
import {
  GDeliveryStatusDmp,
  GGetFulfillmentDeltasPerRouteQuery,
} from 'src/graphql/types/generated';
import { useAppDispatch } from 'src/hooks/store';
import { useTheme } from 'src/hooks/theme';
import { setStopDetailsCardVisible } from 'src/state/slices/delivery';
import { updateRouteQuantity } from 'src/state/slices/fulfillmentDeltas';
import { useQuery } from 'urql';
import { getPrintableStyle, StopNote } from './utils';
import NotesRows from './NotesRow';

type FulfillmentOrderWithIssues = GetFulfillmentOrder & {
  stop: Omit<GetFulfillmentDelivery, 'orders'>;
};

export const FulfillmentByRoute = ({
  route,
  printable,
}: {
  route: Route;
  printable: boolean;
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {
    theme: {
      colors: { secondaryBackgroundActive },
    },
  } = useTheme();
  const printableStyle = getPrintableStyle(printable);
  const [ordersWithFulfillmentIssues, setOrdersWithFulfillmentIssues] =
    useState<FulfillmentOrderWithIssues[]>([]);
  const [driverReminders, setDriverReminders] = useState<StopNote[]>([]);
  const [warehouseNotes, setWarehouseNotes] = useState<StopNote[]>([]);
  const [{ data, fetching, error, stale }] =
    useQuery<GGetFulfillmentDeltasPerRouteQuery>({
      query: GET_FULFILLMENT_DELTAS,
      variables: { input: { id: route.id } },
      requestPolicy: 'cache-and-network',
    });

  useEffect(() => {
    if (!data) return;
    let totalOrderedQuantity = 0;
    let totalFulfilledQuantity = 0;
    const deliveryStops =
      data.route.stops?.filter((stop) => stop.__typename === 'Delivery') ?? [];
    const myOrdersWithFulfillmentIssues: FulfillmentOrderWithIssues[] = [];
    const myWarehouseNotes: StopNote[] = [];
    const myReminderMessages: StopNote[] = [];
    deliveryStops.forEach((stop) => {
      const { orders, ...restOfStop } = stop as GetFulfillmentDelivery;
      if (stop.__typename === 'Delivery' && stop.reminderMessage) {
        myReminderMessages.push({
          stop,
          note: stop.reminderMessage,
        });
      }
      orders?.forEach((order) => {
        if (stop.__typename === 'Delivery' && order.warehouseNote) {
          myWarehouseNotes.push({
            stop,
            note: order.warehouseNote,
          });
        }
        if (!order.itemLevelTracking) return;
        const orderOrderedQty =
          order.orderLines.reduce(
            (accumulator, orderLine): number =>
              accumulator + (orderLine.orderUnitQuantity ?? 0),
            0,
          ) ?? 0;
        const orderFulfilledQty =
          order.orderLines.reduce(
            (accumulator, orderLine) =>
              accumulator + (orderLine.fulfillmentQuantity ?? 0),
            0,
          ) ?? 0;
        totalOrderedQuantity += orderOrderedQty;
        totalFulfilledQuantity += orderFulfilledQty;

        const isFulfillmentDelta = orderOrderedQty !== orderFulfilledQty;
        if (isFulfillmentDelta)
          myOrdersWithFulfillmentIssues.push({ ...order, stop: restOfStop });
      });
    });
    setOrdersWithFulfillmentIssues(myOrdersWithFulfillmentIssues);
    dispatch(
      updateRouteQuantity({
        routeId: route.id,
        orderedQuantity: totalOrderedQuantity,
        fulfilledQuantity: totalFulfilledQuantity,
      }),
    );
    setWarehouseNotes(myWarehouseNotes);
    setDriverReminders(myReminderMessages);
  }, [data]);

  if (fetching) return <LoadingRow numCells={5} />;
  if (!data)
    return <div sx={{ variant: 'text.body' }}>Something went wrong.</div>;

  return (
    <>
      {ordersWithFulfillmentIssues.map((order: FulfillmentOrderWithIssues) => {
        const orderLinesWithFulfillmentIssues = order.orderLines.filter(
          (orderLine) =>
            orderLine.orderUnitQuantity &&
            orderLine.fulfillmentQuantity !== orderLine.orderUnitQuantity,
        );
        return (
          <TableRow key={order.id} data-test-id={order.id}>
            <TableCell
              data-test-id={`fulfillment-route-stop-cell-${order.id}`}
              style={{ ...printableStyle, cursor: 'pointer' }}
              onClick={(): void => {
                const myStop: Delivery | PickUp | undefined = route.stops?.find(
                  (myStop) => myStop.id === order.stop.id,
                );
                if (myStop)
                  dispatch(
                    setStopDetailsCardVisible({
                      stop: myStop,
                      routeId: route.id,
                    }),
                  );
                navigate(`${deliveryPath}?${searchParams.toString()}`);
              }}
            >
              {route.name} Stop {Number(order.stop.sequenceNumber ?? 0) + 1}
            </TableCell>
            <TableCell
              data-test-id={`fulfillment-location-cell-${order.id}`}
              style={printableStyle}
            >
              {order.stop.location?.businessName}
            </TableCell>
            <TableCell
              data-test-id={`fulfillment-vendor-cell-${order.id}`}
              style={printableStyle}
            >
              {order.vendor.name}
            </TableCell>
            {!printable && (
              <TableCell data-test-id={`fulfillment-links-cell-${order.id}`}>
                <OrderLinks
                  orderId={order.id}
                  netsuiteUrl={order.erpRecordUrl}
                  locationId={order.stop.location?.id}
                  flexDirection="column"
                />
              </TableCell>
            )}
            <TableCell
              data-test-id={`fulfillment-order-lines-cell-${order.id}`}
            >
              {orderLinesWithFulfillmentIssues.map(
                (orderLine, orderLineIndex) => (
                  <div key={orderLine.id}>
                    <OrderLineInfo
                      itemLevelTraking={order.itemLevelTracking}
                      orderLine={orderLine as OrderLine}
                      stopId={order.stop.id}
                      orderId={order.id}
                      isStopVisited={
                        order.stop.deliveryStatusDmp !==
                        GDeliveryStatusDmp.InTransit
                      }
                      editable={false}
                    />
                    {orderLineIndex <
                      orderLinesWithFulfillmentIssues.length - 1 && (
                      <Divider
                        type={DividerTypes.dash}
                        color={secondaryBackgroundActive}
                        style={{ marginTop: '16px', marginBottom: '16px' }}
                      />
                    )}
                  </div>
                ),
              )}
            </TableCell>
          </TableRow>
        );
      })}
      {warehouseNotes.map((warehouseNote, index) => {
        return (
          <NotesRows
            key={warehouseNote.stop.id + index}
            note={warehouseNote}
            route={route}
            printable={printable}
            label="Warehouse Note"
          />
        );
      })}
      {driverReminders.map((driverReminder, index) => {
        return (
          <NotesRows
            key={driverReminder.stop.id + index}
            note={driverReminder}
            route={route}
            printable={printable}
            label="Driver Reminder"
          />
        );
      })}
    </>
  );
};

export default FulfillmentByRoute;
