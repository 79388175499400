import { ReactNode } from 'react';
import config from '../config';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { Provider } from 'launchdarkly-react-client-sdk/lib/context';

const LAUNCHDARKLY_CLIENT_SIDE_ID = config().launchdarkly_client_side_id;

// for development proposes only
const mockedFlags = {
  deliveryServiceShowRouteImporter: ['*'],
  deliveryServiceShowAnalyticsPage: true,
  deliveryServiceSeparatePrimaryStatusState: true,
  deliveryServiceFulfillmentDeltasPage: true,
  deliveryServiceShowPackingListPage: true,
  deliveryServiceUnlockRoutingImport: false,
};

const mockedLDContext = {
  flags: mockedFlags,
  ldClient: undefined,
  flagKeyMap: {},
};

const mockedLDProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => <Provider value={mockedLDContext}>{children}</Provider>;

export const initLDProvider = (): Promise<
  ({ children }: { children: ReactNode }) => JSX.Element
> => {
  if (!LAUNCHDARKLY_CLIENT_SIDE_ID) {
    return Promise.resolve(mockedLDProvider);
  }
  return asyncWithLDProvider({ clientSideID: LAUNCHDARKLY_CLIENT_SIDE_ID });
};
