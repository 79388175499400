import GreycliffCFRegularWoff from './GreycliffCF-Regular.woff';
import GreycliffCFRegularWoff2 from './GreycliffCF-Regular.woff2';
import GreycliffCFMediumWoff2 from './GreycliffCF-Medium.woff2';
import GreycliffCFMediumWoff from './GreycliffCF-Medium.woff';
import GreycliffCFBoldWoff2 from './GreycliffCF-Bold.woff2';
import GreycliffCFBoldWoff from './GreycliffCF-Bold.woff';
import GreycliffCFDemiBoldWoff2 from './GreycliffCF-DemiBold.woff2';
import GreycliffCFDemiBoldWoff from './GreycliffCF-DemiBold.woff';
import GreycliffCFExtraBoldWoff2 from './GreycliffCF-ExtraBold.woff2';
import GreycliffCFExtraBoldWoff from './GreycliffCF-ExtraBold.woff';
import GreycliffCFHeavyWoff from './GreycliffCF-Heavy.woff';
import GreycliffCFHeavyWoff2 from './GreycliffCF-Heavy.woff2';
import GreycliffCFLightWoff from './GreycliffCF-Light.woff';
import GreycliffCFLightWoff2 from './GreycliffCF-Light.woff2';

export const fontFace = `
@font-face {
    font-family: 'GreycliffCF-Regular';
    src: local('GreycliffCF-Regular'), local('GreycliffCF-Regular'),
    url(${GreycliffCFRegularWoff2}) format('woff2'),
    url(${GreycliffCFRegularWoff}) format('woff');
};
@font-face {
    font-family: 'GreycliffCF-Medium';
    src: local('GreycliffCF-Medium'), local('GreycliffCF-Medium'),
    url(${GreycliffCFMediumWoff2}) format('woff2'),
    url(${GreycliffCFMediumWoff}) format('woff');
};
@font-face {
    font-family: 'GreycliffCF-Bold';
    src: local('GreycliffCF-Bold'), local('GreycliffCF-Bold'),
    url(${GreycliffCFBoldWoff2}) format('woff2'),
    url(${GreycliffCFBoldWoff}) format('woff');
};
@font-face {
    font-family: 'GreycliffCF-DemiBold';
    src: local('GreycliffCF-DemiBold'), local('GreycliffCF-DemiBold'),
    url(${GreycliffCFDemiBoldWoff2}) format('woff2'),
    url(${GreycliffCFDemiBoldWoff}) format('woff');
};
@font-face {
    font-family: 'GreycliffCF-ExtraBold';
    src: local('GreycliffCF-ExtraBold'), local('GreycliffCF-ExtraBold'),
    url(${GreycliffCFExtraBoldWoff2}) format('woff2'),
    url(${GreycliffCFExtraBoldWoff}) format('woff');
};
@font-face {
    font-family: 'GreycliffCF-Heavy';
    src: local('GreycliffCF-Heavy'), local('GreycliffCF-Heavy'),
    url(${GreycliffCFHeavyWoff2}) format('woff2'),
    url(${GreycliffCFHeavyWoff}) format('woff');
};
@font-face {
    font-family: 'GreycliffCF-Light';
    src: local('GreycliffCF-Light'), local('GreycliffCF-Light'),
    url(${GreycliffCFLightWoff2}) format('woff2'),
    url(${GreycliffCFLightWoff}) format('woff');
}
`;
