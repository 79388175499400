import { ThemeUICSSObject } from 'theme-ui';

export const toast: ThemeUICSSObject = {
  backgroundColor: 'warningBackgroundWeak',
  border: '1px solid #FF760F',
  padding: '10px',
  width: '100%',
  justifyContent: 'space-between',
  fontFamily: 'body',
  borderRadius: 2,
};

export const exit = {
  width: '12px',
  height: '12px',
  '&:hover': {
    cursor: 'pointer',
  },
  marginLeft: '10px',
};
