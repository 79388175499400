import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type AppState = {
  isNavCollapsed: boolean;
};
const initialState = {
  isNavCollapsed: true,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    expandNav: (_: unknown, action: PayloadAction<undefined>) => {
      return { isNavCollapsed: false };
    },
    collapseNav: (_: unknown, action: PayloadAction<undefined>) => {
      return { isNavCollapsed: true };
    },
  },
});

const { reducer, actions } = appSlice;

export const { expandNav, collapseNav } = actions;
export default reducer;
