import EditableLargeTextInput, {
  LargeTextInputProps,
} from './EditableLargeTextInput';

const LargeTextInput = ({
  value,
  isLoading,
  ...props
}: LargeTextInputProps): JSX.Element => {
  return (
    <EditableLargeTextInput {...props} value={value} isLoading={isLoading} />
  );
};
export default LargeTextInput;
