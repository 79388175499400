import { ReactNode } from 'react';
import { keyframes } from '@emotion/react';
import { ThemeUICSSObject } from 'theme-ui';

type RotateProps = {
  startDegrees: number;
  endDegrees: number;
  toggleAnimation?: boolean;
  children: ReactNode;
  id?: string;
  animationSpeed?: number;
};

const Rotate = ({
  startDegrees,
  endDegrees,
  toggleAnimation,
  animationSpeed = 0.5,
  id,
  children,
}: RotateProps): JSX.Element => {
  if (toggleAnimation === undefined) return <div id={id}>{children}</div>;

  const keyFrameForward = keyframes`
  from {
    transform: rotate(${startDegrees}deg);
  }
  to {
    transform: rotate(${endDegrees}deg);
  }
`;

  const keyFrameBackward = keyframes`
  from {
    transform: rotate(${endDegrees}deg);
  }
  to {
    transform: rotate(${startDegrees}deg);
  }
`;

  const rotationForward: ThemeUICSSObject = {
    animation: `${keyFrameForward} ${animationSpeed}s forwards`,
  };

  const rotationBackwards: ThemeUICSSObject = {
    animation: `${keyFrameBackward} ${animationSpeed}s forwards`,
  };

  const style = toggleAnimation ? rotationForward : rotationBackwards;

  return (
    <div
      id={id}
      className={toggleAnimation ? 'rotationForward' : 'rotationBackwards'}
      sx={style}
    >
      {children}
    </div>
  );
};

export default Rotate;
