import { useEffect } from 'react';
import { useAppSelector } from '../../hooks/store';
import { authSelector } from '../../state/selectors/auth';
import { Flex } from 'theme-ui';
import WarehouseDropdown from '../../components/WarehouseDropdown';
import { loginPath } from 'src/components/Navbar';
import { pageContainer, headerWrapper } from '../styles';
import LoggedInContainer from 'src/components/LoggedInContainer';
import { useNavigate } from 'react-router-dom';
import analytics from 'src/services/analytics';
import LocationsTable from 'src/components/LocationsTable';

const Locations = (): JSX.Element => {
  const navigate = useNavigate();
  const { isUserAuthenticated } = useAppSelector(authSelector);

  useEffect(() => {
    analytics.track(`"Locations" Page Visit`);
  }, []);

  useEffect(() => {
    if (!isUserAuthenticated) {
      navigate(loginPath);
    }
  }, [isUserAuthenticated, navigate]);

  return (
    <LoggedInContainer>
      <Flex id="Locations" sx={pageContainer}>
        <div
          id="LocationsHeader"
          sx={{ ...headerWrapper, flexDirection: ['column', 'row'] }}
        >
          <WarehouseDropdown />
        </div>
        <LocationsTable />
      </Flex>
    </LoggedInContainer>
  );
};

export default Locations;
